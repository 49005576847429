export interface ProblemDetails {
  title: string;
  type: string;
  detail: string;
  instance: string;
  status: number;
}

// In .NET, FluentValidator adds this type of extension to the ProblemDetails builder.
export interface ProblemDetailsErrorsExtension {
  errors: {
    [key in string]: string[] | string;
  };
}

export function extractErrorsFromProblemDetailsExtension(
  payload: (ProblemDetails & ProblemDetailsErrorsExtension) | null,
  options?: { format: 'list' | 'raw' }
): string | string[] {
  let literals: string[] = [];

  if (payload === null) {
    return literals;
  }

  if (!payload.errors) {
    return [payload.detail] || literals;
  }

  if (typeof payload.errors !== 'object') {
    return literals;
  }

  const errorsExtensionKeys = Object.keys(payload.errors);

  if (!errorsExtensionKeys.length) {
    return literals;
  }

  for (const k of errorsExtensionKeys) {
    if (payload.errors.hasOwnProperty(k)) {
      const value = payload.errors[k];

      if (Array.isArray(value)) {
        literals = [...literals, ...value];
      }

      if (typeof value === 'string') {
        literals.push(value);
      }
    }
  }

  if (options) {
    if (options.format) {
      if (options.format === 'raw') {
        return literals;
      }

      if (options.format === 'list') {
        return literals.join('\n\n');
      }
    }
  }

  return literals;
}
