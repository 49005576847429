import { PropsWithChildren, useEffect } from 'react';
import image from '~/assets/images/PC_Symbol.svg';
import './header.css';

type HeaderProps = {
  title: string;
  documentTitle?: string;
};

const Header = ({ title, documentTitle, children }: PropsWithChildren<HeaderProps>) => {
  useEffect(() => {
    document.title = `Capacity Tool - ${documentTitle ? documentTitle : title}`;
  }, [documentTitle, title]);

  return (
    <div className="header__container">
      <div className="header__left">
        <img className="header__image" src={image} alt="logo_of_plain_concepts" />

        <div className="header__title ">{title}</div>
      </div>

      <div className="header__right">{children}</div>
    </div>
  );
};

export default Header;
