// Code from https://gist.github.com/mjackson/5311256

function hashGenerator(str, seed = 0) {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0) + '';
}

function hue2rgb(p: number, q: number, t: number) {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
}

function hsl2rgba(rawH: number, rawS: number, rawL: number, alpha = 1): string {
  const h = rawH / 360;
  const s = rawS / 110;
  const l = rawL / 85;
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;

    r = hue2rgb(p, q, h + 1.0 / 3.0);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1.0 / 3.0);
  }

  return `rgba(${Math.round(r * 255)}, ${Math.round(g * 255)}, ${Math.round(b * 255)}, ${alpha})`;
}

// From: https://gist.github.com/xenozauros/f6e185c8de2a04cdfecf
function hexToHSL(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  let r = parseInt(result[1], 16);
  let g = parseInt(result[2], 16);
  let b = parseInt(result[3], 16);
  const cssString = '';

  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;
  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  return { h, s, l };
}

function hslToColor(h: number, s: number, l: number, alpha = 1) {
  return {
    foreground: hsl2rgba(h, s, l * 0.75, alpha),
    background: hsl2rgba(h, s, l * 1.1, alpha)
  };
}

export function rgba2hex(color: string) {
  const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
  const outParts = [
    parseInt(rgba[0]).toString(16),
    parseInt(rgba[1]).toString(16),
    parseInt(rgba[2]).toString(16),
    Math.round(parseInt(rgba[3]) * 255)
      .toString(16)
      .substring(0, 2)
  ];

  let hex = '#';
  // Pad single-digit output values
  outParts.forEach(function (part, i) {
    if (i < outParts.length - 1) hex += part.length === 1 ? '0' + part : part;
  });

  return hex;
}

export function hexToColor(hex: string, alpha = 1) {
  const { h, s, l } = hexToHSL(hex);
  return hslToColor(h, s, l, alpha);
}

// Code from https://gist.github.com/0x263b/2bdd90886c2036a1ad5bcf06d6e6fb37
export function randomColor(rawValue: string, alpha = 1): { foreground: string; background: string } {
  const isSpecial = rawValue?.toLowerCase().includes('special');

  const hue = isSpecial ? [0, 360] : [38, 360];
  const sat = isSpecial ? [40, 60] : [65, 75];
  const lit = isSpecial ? [55, 60] : [50, 65];

  const value = hashGenerator(rawValue || '');

  const range = function (hash, min, max) {
    const diff = max - min;
    const x = ((hash % diff) + diff) % diff;
    return x + min;
  };

  let hash = 0;
  if (value === undefined || value.length === 0) {
    return { foreground: '#000', background: '#fff' };
  }

  for (let i = 0; i < value.length; i++) {
    hash = value.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }

  const h = range(hash, hue[0], hue[1]);
  const s = range(hash, sat[0], sat[1]);
  const l = range(hash, lit[0], lit[1]);

  return hslToColor(h, s, l, alpha);
}

export function fadeColor(hexColor: string, factor: number): string {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  const atenuadoR = Math.floor(r * factor);
  const atenuadoG = Math.floor(g * factor);
  const atenuadoB = Math.floor(b * factor);

  return `#${atenuadoR.toString(16)}${atenuadoG.toString(16)}${atenuadoB.toString(16)}`;
}

export function getColorWithOpacity(color: string, opacidad: number): string {
  const [r, g, b] = color.match(/\w\w/g)?.map((c) => parseInt(c, 16)) || [0, 0, 0];
  return `rgba(${r}, ${g}, ${b}, ${opacidad})`;
}

export function invertColor(hex) {
  // Remove the '#' from the beginning if present
  if (hex.startsWith('#')) {
    hex = hex.slice(1);
  }

  // Convert 3-digit HEX to 6-digits (e.g., #ABC to #AABBCC)
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // Invert color components
  const r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16).toUpperCase().padStart(2, '0');
  const g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16).toUpperCase().padStart(2, '0');
  const b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16).toUpperCase().padStart(2, '0');

  // Combine the inverted components to get the opposite color
  return `#${r}${g}${b}`;
}

export const COLOR_ALPHA_TAGS = 0.15;
