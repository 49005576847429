import {
  BillingCycle,
  BillingCycleProject,
  BillingCycleYearsResponse,
  formatDateForServer,
  HttpFetchResponse,
  httpQueryStringParser
} from '~/app/shared';

export interface BillingCycleServiceGetWithEffortByProductIdOptions {
  productId: number;
  abortController: AbortController;
}

export interface GetWithEffortByProjectIdBody {
  projectId: number;
  billingCyclesIds: number[];
  withExternalEffort: boolean;
  abortController: AbortController;
}

export interface GetWithFinancialByProjectIdBody {
  projectId: number;
  billingCyclesIds: number[];
  abortController: AbortController;
}

class BillingCycleService {
  public async getWithEffortByProductId(
    options: BillingCycleServiceGetWithEffortByProductIdOptions
  ): Promise<HttpFetchResponse<Pick<BillingCycle, 'id' | 'year' | 'month'>[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/billingCycle/product/${options.productId}`;

    const response = await fetch(url, { signal: options.abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getWithEffortByProjectId(
    options: GetWithEffortByProjectIdBody
  ): Promise<HttpFetchResponse<BillingCycleProject[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/billingCycle/project/${options.projectId}`;
    httpQueryStringParser.encodeOneDimensionArray('billingCyclesIds', url.searchParams, options.billingCyclesIds);
    url.searchParams.set('withExternalEffort', `${options.withExternalEffort}`);

    const response = await fetch(url, { signal: options.abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getWithFinancialByProjectId(
    options: GetWithFinancialByProjectIdBody
  ): Promise<HttpFetchResponse<BillingCycleProject[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/billingCycle/project/financial/${options.projectId}`;
    httpQueryStringParser.encodeOneDimensionArray('billingCyclesIds', url.searchParams, options.billingCyclesIds);

    const response = await fetch(url, { signal: options.abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getByYear(year: number, abortController: AbortController): Promise<HttpFetchResponse<BillingCycle[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/billingCycle/${year}`;

    const response = await fetch(url, { signal: abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody.items, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getYears(abortController: AbortController): Promise<HttpFetchResponse<BillingCycleYearsResponse>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = '/billingCycle/years';

    const response = await fetch(url, { signal: abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getTwoYearsFromCurrent(abortController: AbortController): Promise<HttpFetchResponse<BillingCycle[]>> {
    const today = new Date();
    const initDate = new Date(2022, 0, 1);
    const endDate = new Date(today.getFullYear() + 2, 11, 31);

    const from = formatDateForServer(initDate);
    const to = formatDateForServer(endDate);

    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/billingCycle/between/${from}/${to}`;

    const response = await fetch(url, { signal: abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody.items, status: response.status };
    }

    return { data: null, status: response.status };
  }
}

export const billingCycleService = new BillingCycleService();
