import { format, parseISO } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';
import { t } from '~/app/app.i18n';
import { employeesService } from '~/app/employees/shared/employees.service';
import { Paginated } from '../fetch';
import { FormSelectOption } from '../form';
import { HttpFetchResponse } from '../http';
import { PaginationParameters } from '../pagination/pagination.model';
import { Employee } from './employee.model';

export function getDateFormatedPicker(date: Date): string {
  return format(parseISO(date.toString()), 'yyyy-MM-dd');
}

export function getDateFormated(date: Date): string {
  return format(parseISO(date.toString()), 'dd-MM-yyyy');
}

export function getBooleanFormatedAsYesOrNo(isExternal: boolean): string {
  if (isExternal === false) {
    return t('employees.list.no');
  }
  return t('employees.list.yes');
}

export async function employeePromiseFilter(
  employees: Employee[],
  get: (
    skip?: number,
    take?: number,
    filter?: string,
    paginationParameters?: PaginationParameters
  ) => Promise<Paginated<Employee>>,
  setEmployees: Dispatch<SetStateAction<Employee[]>>,
  inputValue: string
): Promise<FormSelectOption[]> {
  const text = inputValue.toLowerCase();
  let employeesFiltered: Employee[] = employees?.filter((employee) => employee.name.toLowerCase().includes(text));
  if (!employeesFiltered || employeesFiltered?.length === 0) {
    const paginatedEmployees = await get(undefined, 600, text, {
      propertySort: 'name',
      isAscending: true,
      pageNumber: 0,
      pageSize: 600
    });
    employeesFiltered = paginatedEmployees.items;
    setEmployees(employeesFiltered);
  }
  return employeesFiltered?.map((x: Employee) => ({ value: x.id, label: x.name }));
}

export async function employeeEngineersPromiseFilter(
  employees: Employee[],
  get: (skip?: number, take?: number, filter?: string) => Promise<HttpFetchResponse<Paginated<Employee>>>,
  setEmployees: Dispatch<SetStateAction<Employee[]>>,
  inputValue: string
): Promise<FormSelectOption[]> {
  const text = inputValue.toLowerCase();
  let employeesFiltered: Employee[] = employees?.filter((employee) => employee.name.toLowerCase().includes(text));
  if (!employeesFiltered || employeesFiltered?.length === 0) {
    const { data: paginatedEmployees } = await get(undefined, 600, text);

    employeesFiltered = paginatedEmployees.items;
    setEmployees(employeesFiltered);
  }
  return employeesFiltered?.map((x: Employee) => ({ value: x.id, label: x.name }));
}

export async function employeeManagersPromiseFilter(
  employees: Employee[],
  setEmployees: Dispatch<SetStateAction<Employee[]>>,
  inputValue: string
): Promise<FormSelectOption[]> {
  const filteredEmployees = employees?.filter((employee) =>
    employee.name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
  );

  let effectiveEmployees: Employee[] = [];

  if (inputValue) {
    effectiveEmployees = filteredEmployees;
  } else {
    const { data } = await employeesService.getManagers();
    effectiveEmployees = data;
  }

  !inputValue && setEmployees(effectiveEmployees);

  return effectiveEmployees?.map((x: Employee) => ({ value: x.id, label: x.name }));
}
