import { lazy } from 'react';
import { Route } from 'react-router-dom';

const Reports = lazy(() => import('./EffortReports'));
const ReportsNoUnit = lazy(() => import('./EffortReportsNoUnit'));

export default (
  <>
    <Route path="/effort-reports" element={<ReportsNoUnit />} />
    <Route path="/effort-reports/:unit" element={<Reports />} />
  </>
);
