interface MaterialCheckCircleIconProps {
  size: 20 | 24;
}

export const MaterialCheckCircleIcon: React.FunctionComponent<MaterialCheckCircleIconProps> = ({ size }) => {
  switch (size) {
    case 20:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
          <path
            fill="currentColor"
            d="m8.938 13 4.958-4.938L12.833 7l-3.895 3.875-1.771-1.75-1.063 1.063ZM10 18q-1.646 0-3.104-.625-1.458-.625-2.552-1.719t-1.719-2.552Q2 11.646 2 10q0-1.667.625-3.115.625-1.447 1.719-2.541Q5.438 3.25 6.896 2.625T10 2q1.667 0 3.115.625 1.447.625 2.541 1.719 1.094 1.094 1.719 2.541Q18 8.333 18 10q0 1.646-.625 3.104-.625 1.458-1.719 2.552t-2.541 1.719Q11.667 18 10 18Z"
          />
        </svg>
      );
    case 24:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
          <path
            fill="currentColor"
            d="m10.6 16.6 7.05-7.05-1.4-1.4-5.65 5.65-2.85-2.85-1.4 1.4ZM12 22q-2.075 0-3.9-.788-1.825-.787-3.175-2.137-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175 1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138 1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175-1.35 1.35-3.175 2.137Q14.075 22 12 22Z"
          />
        </svg>
      );
    default:
      break;
  }

  return null;
};
