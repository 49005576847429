import { RefObject, useEffect } from 'react';

function useOutsideClick(ref: RefObject<HTMLElement>, callback: () => void, ignoreRef?: RefObject<HTMLElement>) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        // Si hay un ignoreRef y el clic fue dentro de ese elemento, no hacemos nada
        if (ignoreRef && ignoreRef.current && ignoreRef.current.contains(event.target as Node)) {
          return;
        }
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, ignoreRef, callback]);
}

export default useOutsideClick;
