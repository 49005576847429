import { lazy } from 'react';
import { Route } from 'react-router-dom';

const Products = lazy(() => import('./products.route'));
const ProductsById = lazy(() => import('./products_[id].route'));

export const publicRoutes = (
  <>
    <Route path="/products/:id" element={<ProductsById />} />
  </>
);

export const privateRoutes = (
  <>
    <Route path="/products" element={<Products />} />
  </>
);
