import { Tooltip } from '../tooltip';

type TableCellProps = {
  value: unknown;
};

export function TableCell(props: TableCellProps) {
  const { value } = props;

  return (
    <Tooltip title={value}>
      <span>{value}</span>
    </Tooltip>
  );
}
