import { BillingCycle, BillingCycleYearsGroup, groupBillingCyclesByYear } from '~/app/shared/billing-cycle';
import { chunkArray } from '../chunk.helper';

function transformBillingCyclesToDropdownData(groupedByYear: BillingCycleYearsGroup, selectedCycles: BillingCycle[]) {
  return Object.keys(groupedByYear).map((year) => {
    const quarters = chunkArray(groupedByYear[year], 3);
    const yearNode = { label: year, children: [], value: [] };
    const flatSelectedCycles = selectedCycles.map((y) => y.id);

    quarters.forEach((quarter, index) => {
      const quarterNode = {
        label: `Q${index + 1} - ${year}`,
        children: [],
        value: []
      };

      quarter.forEach((monthCycle) => {
        const monthNode = {
          label: `${monthCycle.month} - ${year}`,
          value: [monthCycle.id],
          checked: selectedCycles.find((selected) => selected.id === monthCycle.id)
        };

        quarterNode.children.push(monthNode);
        quarterNode.value.push(monthCycle.id);
      });

      yearNode.children.push({
        ...quarterNode,
        checked: quarterNode.value.every((x) => flatSelectedCycles.includes(x))
      });

      yearNode.value = [...yearNode.value, ...quarterNode.value];
    });

    return {
      ...yearNode,
      checked: yearNode.value.every((x) => flatSelectedCycles.includes(x)),
      expanded: yearNode.value.some((x) => flatSelectedCycles.includes(x))
    };
  });
}

export function billingCyclesToDropdownTree(cycles: BillingCycle[], selectedCycles: BillingCycle[]) {
  const groupedByYear = groupBillingCyclesByYear(cycles);
  const transformedData = transformBillingCyclesToDropdownData(groupedByYear, selectedCycles);

  return transformedData;
}
