interface MaterialFunnelIconProps {
  size: number;
  color?: string;
}

export const MaterialFunnelIcon: React.FunctionComponent<MaterialFunnelIconProps> = ({ size, color = '#8E8F95' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size}>
      <path
        d="M1.3095 1.16699H12.1428C12.2628 1.20908 12.3716 1.27808 12.4609 1.36871C12.5501 1.45934 12.6174 1.56919 12.6576 1.68984C12.6978 1.8105 12.7099 1.93876 12.6929 2.0648C12.6759 2.19084 12.6303 2.31131 12.5595 2.41699L8.39283 7.00033V12.8337L5.0595 10.3337V7.00033L0.89283 2.41699C0.822073 2.31131 0.776443 2.19084 0.759437 2.0648C0.742431 1.93876 0.754499 1.8105 0.794717 1.68984C0.834935 1.56919 0.902237 1.45934 0.991465 1.36871C1.08069 1.27808 1.18948 1.20908 1.3095 1.16699Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};
