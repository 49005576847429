import { Ability, AbilityBuilder } from '@casl/ability';
import { EmployeeRole } from '../employee';
import { AuthAction } from './auth-action.enum';
import { AuthSubject } from './auth-subject.enum';
import { mapAuthAction, mapAuthSubject } from './auth.helper';

type AbilityTupleType = [Lowercase<keyof typeof AuthAction>, Lowercase<keyof typeof AuthSubject>];
type AbilityType = Ability<AbilityTupleType>;

function defineAbilities(): Ability {
  return new Ability<AbilityTupleType>(new AbilityBuilder<AbilityType>(Ability).rules);
}

export const ability = defineAbilities();

export function updateAbilities(employeeRole: EmployeeRole): void {
  const { rules, can: allow } = new AbilityBuilder(Ability);

  employeeRole.scopes?.forEach((scope) => {
    scope.abilities?.forEach((ability) => {
      allow(mapAuthAction(ability.action), mapAuthSubject(ability.subject));
    });
  });

  ability.update(rules);
}
