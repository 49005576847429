import { DomainErrorResponseBody } from './types';

export class ApiFetchError extends Error {
  public static ErrorName = 'ApiFetchError';

  constructor(public response: Response, public responseBody: any, private hasBeenIntercepted: boolean = false) {
    super(`${response.url}: ${response.status}_${response.statusText}`);
  }

  public static hasBeenIntercepted(error: unknown) {
    if (error instanceof ApiFetchError) {
      return error.hasBeenIntercepted;
    }

    return false;
  }

  public static getDomainErrorBody(error: unknown): DomainErrorResponseBody {
    if (error instanceof ApiFetchError) {
      const { responseBody } = error;

      if (responseBody?.type && responseBody?.type === 'about:blank' && responseBody.exceptionDetails) {
        return responseBody as DomainErrorResponseBody;
      }
    }
  }
}
