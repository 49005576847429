import { lazy } from 'react';
import { Route } from 'react-router-dom';

const BillingUnit = lazy(() => import('./list/billings-unit'));

export const routes = (
  <>
    <Route path="/unit-billing" element={<BillingUnit />} />
    <Route path="/unit-billing/:year" element={<BillingUnit />} />
    <Route path="/unit-billing/:year/:month" element={<BillingUnit />} />
  </>
);
