import { useCallback, useRef } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { components as Components, GroupBase, InputProps, SelectComponentsConfig } from 'react-select';
import AsyncSelect from 'react-select/async';
import { isAbortError } from '../error';

import { FormSelectOption } from './form-select-option.model';

import './form-select.css';

type FormAsyncSelectProps = {
  name: string;
  small?: boolean;
  promiseOptions?: (inputValue: string) => Promise<FormSelectOption[]>;
  control?: Control<any>;
  placeholder?: string;
  rules?: unknown;
  customStyles?: unknown;
  className?: string;
  components?: SelectComponentsConfig<unknown, boolean, GroupBase<unknown>>;
  isSearchable?: boolean;
  isMultiple?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
};

export function FormAsyncSelect(props: FormAsyncSelectProps) {
  const {
    name,
    small,
    promiseOptions,
    control,
    customStyles,
    className,
    rules,
    placeholder = '',
    components,
    isSearchable = true,
    isMultiple = false,
    isClearable = false,
    disabled = false
  } = props;

  const [t] = useTranslation();
  const asyncSelectRef = useRef();

  const onFocusSelect = useCallback(() => {
    asyncSelectRef && asyncSelectRef?.current && (asyncSelectRef?.current as any)?.inputRef?.current?.focus();
  }, []);

  const loadOptions = useCallback(
    (inputValue: string) => {
      try {
        return promiseOptions(inputValue);
      } catch (error) {
        if (!isAbortError(error)) {
          console.error(error);
        }

        return Promise.resolve([]);
      }
    },
    [promiseOptions]
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => {
        const Input = (props: InputProps) => {
          const invalidClass = fieldState.invalid ? 'is-invalid' : '';

          if (!props.isMulti) {
            return (
              <div className={`custom-form-select__input form-select ${invalidClass}`}>
                <Components.Input {...props} />
              </div>
            );
          }

          return <Components.Input {...props} />;
        };

        return (
          <AsyncSelect
            {...field}
            styles={customStyles}
            cacheOptions
            ref={asyncSelectRef}
            loadOptions={loadOptions}
            defaultOptions={true}
            components={{ Input, ...components }}
            classNamePrefix={!props.isMultiple ? 'custom-form-select' : 'custom-form-select-multiple'}
            blurInputOnSelect
            closeMenuOnSelect
            menuPlacement="auto"
            placeholder={placeholder}
            isClearable={isClearable}
            onFocus={onFocusSelect}
            isOptionDisabled={(option) => option?.disabled}
            loadingMessage={() => t('common.loading')}
            noOptionsMessage={() => t('form.noResults')}
            className={`${small ? 'custom-form-select--small' : ''} ${fieldState.invalid ? 'is-invalid' : ''} ${
              className ? className : ''
            }`}
            isSearchable={isSearchable}
            isMulti={isMultiple}
            isDisabled={disabled}
          />
        );
      }}
    />
  );
}
