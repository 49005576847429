import { Area } from '../area/area.model';
import { AuthAction, AuthSubject } from '../auth';
import { Company } from '../company/company.model';
import { PaginationParameters } from '../pagination/pagination.model';
import { Team } from '../team';
import { Unit } from '../unit';
import { EmployeeJobType } from './employee-job-type.enum';

type EmployeeRoleAbility = {
  action: AuthAction;
  actionName?: string;
  subject: AuthSubject;
  subjectName?: string;
};

type EmployeeRoleScope = {
  scope: number;
  scopeName?: string;
  abilities?: EmployeeRoleAbility[];
};

export type EmployeeRole = {
  id: number;
  name: string;
  hierarchy: number;
  isNotAssigned: boolean;
  scopes?: EmployeeRoleScope[];
};

export type Employee = {
  id?: number;
  name: string;
  team?: { id: number; number?: number };
  managementTeam?: { id: number; number?: number };
  unit?: { id: number; name?: string };
  hiringDate: Date;
  endDate?: Date;
  email: string;
  area?: Area;
  code?: string;
  employeeRole?: EmployeeRole;
  isMaintenance?: boolean;
  isExternal: boolean;
  jobType: EmployeeJobType;
};

// New employee definition

export type EmployeeDetail = {
  id: number;
  name: string;
  email: string;
  code: string;
  company: Company;
  managementTeam?: Team;
  team?: Team;
  unit?: Unit;
  area?: Area;
  employeeRole?: EmployeeRole;
  hiringDate: Date;
  endDate?: Date;
  isExternal: boolean;
  isDeleted: boolean;
};

export interface ProductDetailEmployeesEmployee {
  name: string;
  unitName: string | null;
  teamId: number | null;
  teamAlias: string | null;
  businessLineName: string | null;
  effort: number | null;
  effortNonBillable: number | null;
  teamNumber: number | null;
}

export type HeaderFilter = {
  id: string;
  value: number[];
};

export type PaginatedEmployee<T> = {
  items: T[];
  total: number;
  totalInactives: number;
};

export interface EmployeesByFilter {
  filterTab?: EmployeeListTab;
  filter: string;
  paginationParameters: PaginationParameters;
  headerFilters: HeaderFilter[];
}
export interface EmployeeByRoleResponse {
  id: number;
  name: string;
  email: string;
  code: string | null;
}

export enum EmployeeListTab {
  Actives = 0,
  Inactives = 1,
  Exemployees = 2,
  Externals = 3,
  Archived = 4
}

export interface ExternalEffortCreate {
  year: number;
  month: number;
  effort: number;
  productId: number;
  employeeId: number;
}

export interface ExternalEffortEdit {
  id: number;
  effort: number;
}

export interface ExternalEffortDelete {
  ids: number[];
}
