interface MaterialWarningIconProps {
  size: 24;
}

export const MaterialWarningIcon: React.FunctionComponent<MaterialWarningIconProps> = ({ size }) => {
  switch (size) {
    case 24:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
          <path
            fill="currentColor"
            d="M1 21 12 2l11 19Zm11-3q.425 0 .713-.288Q13 17.425 13 17t-.287-.712Q12.425 16 12 16t-.712.288Q11 16.575 11 17t.288.712Q11.575 18 12 18Zm-1-3h2v-5h-2Z"
          />
        </svg>
      );
    default:
      break;
  }

  return null;
};
