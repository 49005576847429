import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';

import { HttpStatus } from '~/app/shared';

import { composeAuthenticationScopes, composeAuthenticationTokenByMsalInstance } from './compose-auth-token';

export function useProxifyFetch(): void {
  const { instance } = useMsal();

  useEffect(
    function proxifyFetch(): void {
      globalThis.fetch = new Proxy(globalThis.fetch, {
        apply: async (target, _, args: Parameters<typeof globalThis.fetch>): Promise<Response> => {
          const requestInfo: Parameters<typeof globalThis.fetch>[0] = args[0];
          const requestInit: Parameters<typeof globalThis.fetch>[1] = args[1] ?? {};

          // Is Microsoft MSAL login discovery request or other external requests.
          // Also we can check some other condition like the origin but
          // this can change in any time from the Microsoft part.
          //
          // Then, we can define that all the controlled requests of the app
          // are maked with a RequestInfo of type URL and not as string.
          if (typeof requestInfo === 'string') return target(requestInfo, requestInit);

          const authorizationToken = await composeAuthenticationTokenByMsalInstance(instance);

          const requestInitHeaders: Headers = new Headers(requestInit.headers);
          requestInitHeaders.append('authorization', authorizationToken);
          requestInit.headers = requestInitHeaders;

          const response = await target(requestInfo, requestInit);

          if (response.status === HttpStatus.StatusUnauthorized) {
            try {
              await instance.acquireTokenRedirect({ scopes: composeAuthenticationScopes() });
            } catch (error) {
              console.error('Cannot redirect to login', error);
            }
          }

          return response;
        }
      });
    },
    [instance]
  );
}
