import { createStore, ReducerType, useStore } from 'react-hookstore';
import { GenericPayload } from '~/app/core';
import { EmployeeRole } from '../employee';
import { EmployeeJobType } from '../employee/employee-job-type.enum';

const name = 'ROOT/AUTH';

enum Type {
  SET_ID = 'ROOT/AUTH/SET_ID',
  SET_NAME = 'ROOT/AUTH/SET_NAME',
  SET_USER_NAME = 'ROOT/AUTH/SET_USER_NAME',
  SET_ROLES = 'ROOT/AUTH/SET_ROLES',
  SET_EMPLOYEE_ROLE = 'ROOT/AUTH/SET_EMPLOYEE_ROLE',
  SET_EMPLOYEE_UNITID = 'ROOT/AUTH/SET_EMPLOYEE_UNITID',
  SET_EMPLOYEE_ROLE_NAME = 'ROOT/AUTH/SET_EMPLOYEE_ROLE_NAME',
  SET_MAINTENANCE = 'ROOT/AUTH/SET_MAINTENANCE',
  SET_JOB_TYPE = 'ROOT/AUTH/SET_JOB_TYPE'
}

type Payload = GenericPayload<Type>;

interface State {
  id: number;
  name: string;
  username: string;
  roles: string[];
  role: EmployeeRole | null;
  unitId: number;
  roleName: string;
  maintenance: boolean;
  email: string;
  jobType: EmployeeJobType;
}

const state: State = {
  id: null,
  name: null,
  username: null,
  roles: [],
  role: null,
  unitId: null,
  roleName: null,
  maintenance: false,
  email: null,
  jobType: EmployeeJobType.Other
};

const reducers: ReducerType<State, Payload> = function (state: State, { type, payload }) {
  switch (type) {
    case Type.SET_ID:
      return { ...state, id: payload };
    case Type.SET_NAME:
      return { ...state, name: payload };
    case Type.SET_USER_NAME:
      return { ...state, username: payload };
    case Type.SET_ROLES:
      return { ...state, roles: payload };
    case Type.SET_EMPLOYEE_ROLE:
      return { ...state, role: payload };
    case Type.SET_EMPLOYEE_ROLE_NAME:
      return { ...state, roleName: payload };
    case Type.SET_EMPLOYEE_UNITID:
      return { ...state, unitId: payload };
    case Type.SET_MAINTENANCE:
      return { ...state, maintenance: payload };
    case Type.SET_JOB_TYPE:
      return { ...state, jobType: payload };
    default:
      return { ...state };
  }
};

const store = createStore<State>(name, state, reducers);

export const AuthStoreType = Type;
export type AuthStoreState = State;
export const useAuthStore = () => useStore<State>(store);
