export function downloadFileFromBlob(blob: Blob, filename: string): void {
  const downloadAnchor = document.createElement('a') as HTMLAnchorElement;
  const downloadUrl = URL.createObjectURL(blob);
  downloadAnchor.href = downloadUrl;
  downloadAnchor.download = filename;
  document.body.appendChild(downloadAnchor);
  downloadAnchor.click();
  document.body.removeChild(downloadAnchor);
  URL.revokeObjectURL(downloadUrl);
}
