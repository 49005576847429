export function getInitials(text, amount = 2, amountWord = 1) {
  const splitted = text?.split(' ');

  if (!splitted) {
    return '';
  }

  if (splitted.length < 2) {
    return text.slice(0, amount);
  }

  return splitted
    .map((x) => x.slice(0, amountWord))
    .slice(0, amount)
    .join('');
}
