import { TFunction } from 'i18next';
import { AlertCircle, Basket, Beach, BuildingCommunity, CalendarEvent, Notebook } from 'tabler-icons-react';
import { ColorConstants } from './product.helper';
import { Product } from './product.model';

interface ForecastSpecialEffort {
  id: number;
  key: string;
  iconId: number;
}
export interface ForecastSpecialProduct extends Product {
  iconId: number;
}

export const SPECIAL_KEY = 'SPECIAL';

export const specialIcons = [
  {
    id: 0,
    icon: AlertCircle,
    box: 'Idle',
    description: 'forecast.legend.info.idle',
    theme: '#fffac9'
  },
  {
    id: 1,
    icon: Notebook,
    box: 'Other',
    description: 'forecast.legend.info.other',
    theme: '#d4f5fc'
  },
  {
    id: 2,
    icon: CalendarEvent,
    box: 'Bank holiday',
    description: 'forecast.legend.info.bank',
    theme: '#dfedff'
  },
  {
    id: 3,
    icon: Beach,
    box: 'Vacation',
    description: 'forecast.legend.info.vacation',
    theme: '#f3ecff'
  },
  {
    id: 4,
    icon: Basket,
    box: 'Sales support',
    description: 'forecast.legend.info.sales',
    theme: '#e9fffb'
  },
  {
    id: 5,
    icon: BuildingCommunity,
    box: 'Sick or leave',
    description: 'forecast.legend.info.sick',
    theme: '#ffe9fa'
  }
];

const FORECAST_SPECIAL_EFFORT: readonly ForecastSpecialEffort[] = Object.freeze([
  {
    id: 1,
    key: 'forecast.special-effort.vacation',
    iconId: 3
  },
  {
    id: 2,
    key: 'forecast.special-effort.sick-or-leave',
    iconId: 5
  },
  {
    id: 3,
    key: 'forecast.special-effort.sales-support',
    iconId: 4
  },
  {
    id: 4,
    key: 'forecast.special-effort.bank-holiday',
    iconId: 2
  },
  {
    id: 5,
    key: 'forecast.special-effort.idle',
    iconId: 0
  },
  {
    id: 6,
    key: 'forecast.special-effort.other',
    iconId: 1
  },
  {
    id: 7,
    key: 'forecast.special-effort.not-available',
    iconId: 7
  }
]);

function getForecastSpecialEffortProducts(t?: TFunction): ForecastSpecialProduct[] {
  const translator = t || ((key: string) => key);

  const forecastSpecialEffortProducts = FORECAST_SPECIAL_EFFORT.map((value) => {
    const forecastSpecialEffortProductName: string = translator(value.key);

    const forecastSpecialEffortProduct: ForecastSpecialProduct = {
      id: value.id,
      name: forecastSpecialEffortProductName,
      isSpecialEffort: true,
      clientName: SPECIAL_KEY,
      scope: null,
      status: null,
      quoteId: null,
      type: null,
      color: ColorConstants.EmptyColor,
      iconId: value.iconId
    };

    return forecastSpecialEffortProduct;
  });

  return forecastSpecialEffortProducts.sort((prev, curr) => prev.name.localeCompare(curr.name));
}

export interface SpecialProducts extends Product {
  item: any;
}

function buildSpecialEffortProductsWithIcons(t?: TFunction): SpecialProducts[] {
  const list: SpecialProducts[] = getForecastSpecialEffortProducts(t).map((effort) => ({
    ...effort,
    item: specialIcons.find((icon) => icon.id === effort.iconId)
  }));

  const sortedList = list.filter((effort) => !!effort.item).sort((a, b) => a.item.id - b.item.id);

  return sortedList;
}

export { buildSpecialEffortProductsWithIcons, getForecastSpecialEffortProducts };
