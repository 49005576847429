interface MaterialCloseIconProps {
  size: 16 | 20 | 24;
}

export const MaterialCloseIcon: React.FunctionComponent<MaterialCloseIconProps> = ({ size }) => {
  switch (size) {
    case 16: {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M12 4L4 12M4 4L12 12"
            stroke="#8E8F95"
            strokeWidth="1.33"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case 20:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
          <path
            fill="currentColor"
            d="M6.062 15 5 13.938 8.938 10 5 6.062 6.062 5 10 8.938 13.938 5 15 6.062 11.062 10 15 13.938 13.938 15 10 11.062Z"
          />
        </svg>
      );
    case 24:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
          <path
            fill="currentColor"
            d="M6.4 19 5 17.6l5.6-5.6L5 6.4 6.4 5l5.6 5.6L17.6 5 19 6.4 13.4 12l5.6 5.6-1.4 1.4-5.6-5.6Z"
          />
        </svg>
      );
    default:
      break;
  }

  return null;
};
