import { Toaster } from 'react-hot-toast';

import { composei18n } from './app.i18n';
import { AppRouter } from './AppRouter';
import { useEmployeeSession, useProxifyFetch } from './auth';
import { ability, AbilityContext } from './shared';

composei18n();

const App: React.FunctionComponent = () => {
  useProxifyFetch();

  const abilitiesAreUpdated = useEmployeeSession();

  return (
    <AbilityContext.Provider value={ability}>
      {abilitiesAreUpdated ? <AppRouter /> : null}

      <div className="toaster">
        <Toaster position="top-right" />
      </div>
    </AbilityContext.Provider>
  );
};

export default App;
