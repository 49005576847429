import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { PageSpin } from '~/components/PageSpin';

const Forecast = lazy(() => import('./Forecast'));
const ForecastLayout = lazy(() => import('./ForecastLayout'));

export default (
  <Route path="/forecast" element={<ForecastLayout />}>
    <Route index element={<PageSpin />} />
    <Route path=":unit/:team/:year" element={<Forecast />} />
  </Route>
);
