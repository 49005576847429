import { useCallback } from 'react';
import DropdownTreeSelect, { TreeData, TreeNode } from 'react-dropdown-tree-select';
import './dropdown-tree.css';

type DropdownTreeProps = {
  data: TreeData;
  disabled?: boolean;
  onChange?: (value: number[]) => void;
};

export const DropdownTree = (props: DropdownTreeProps) => {
  const { data, onChange = () => null, disabled = false } = props;

  const onChangeSelect = useCallback(
    (currentNode: TreeNode) => {
      const values = Array.isArray(currentNode.value)
        ? currentNode.value.map((x) => Number(x))
        : [Number(currentNode.value)];

      onChange(values);
    },
    [onChange]
  );

  return (
    <div className="dropdown-tree">
      <DropdownTreeSelect
        disabled={disabled}
        data={data}
        onChange={onChangeSelect}
        keepOpenOnSelect={true}
        showPartiallySelected={true}
      />
    </div>
  );
};
