import { Tooltip } from '..';
import { COLOR_ALPHA_TAGS, randomColor } from '../color';

type TableCellBadgeProps = {
  text: string;
  children?: React.ReactNode;
  color?: { foreground: string; background: string };
};

export function TableCellBadge(props: TableCellBadgeProps) {
  const { text, color, children } = props;
  const badgeColor = color || randomColor(text);
  const backgroundOpacity = randomColor(text, COLOR_ALPHA_TAGS);

  // Don't use React Bootstrap component because forces color
  return (
    <Tooltip title={text}>
      <span className="badge" style={{ background: backgroundOpacity.background, color: badgeColor.foreground }}>
        {text} {children}
      </span>
    </Tooltip>
  );
}
