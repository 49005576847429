import { ApiFetch } from './ApiFetch';
import { ApiFetchError } from './ApiFetchError';
import { DomainErrorResponseInterceptor } from './interceptors/DomainErrorResponseInterceptor';
import { FluentErrorResponseInterceptor } from './interceptors/FluentErrorResponseInterceptor';
import { IResponseInterceptor } from './IResponseInterceptor';
import type { ApiFetchBodyRequestInit, ApiFetchRequestInit, ApiFetchResponse } from './types';

export class ApiFetchClient implements ApiFetch {
  private static apiUrl: string = process.env.REACT_APP_API_URL;
  private static baseHeaders: HeadersInit = { 'Content-Type': 'application/json' };
  private static responseInterceptors: IResponseInterceptor[] = [
    new FluentErrorResponseInterceptor(),
    new DomainErrorResponseInterceptor()
  ];
  private readonly baseUrl: URL;

  constructor(baseUrl = '') {
    this.baseUrl = new URL(`${ApiFetchClient.apiUrl}/${baseUrl}`);
  }

  public async get<TResponse = unknown>(
    path: string,
    params?: Record<string, string>,
    init: ApiFetchRequestInit = {}
  ): Promise<ApiFetchResponse<TResponse>> {
    const url = this.buildURL(path, params);
    const headers = this.mergeHeaders(init.headers);

    const response = await this.executeFetch(url, {
      ...init,
      method: 'GET',
      headers
    });

    await this.checkResponse(response);
    const data = await this.getDataFromResponse<TResponse>(response);

    return {
      data,
      response
    };
  }

  public async delete<TResponse = void>(
    path: string,
    params?: Record<string, string>,
    init: ApiFetchRequestInit = {}
  ): Promise<ApiFetchResponse<TResponse>> {
    const url = this.buildURL(path, params);
    const headers = this.mergeHeaders(init.headers);

    const response = await this.executeFetch(url, {
      ...init,
      method: 'DELETE',
      headers
    });

    await this.checkResponse(response);
    const data = await this.getDataFromResponse<TResponse>(response);

    return {
      data,
      response
    };
  }

  public async post<TResponse = void>(
    path: string,
    body: unknown,
    params?: Record<string, string>,
    init: ApiFetchBodyRequestInit = {}
  ): Promise<ApiFetchResponse<TResponse>> {
    const url = this.buildURL(path, params);
    const headers = this.mergeHeaders(init.headers);

    const response = await this.executeFetch(url, {
      ...init,
      method: 'POST',
      headers,
      body: JSON.stringify(body)
    });

    await this.checkResponse(response);
    const data = await this.getDataFromResponse<TResponse>(response);

    return {
      data,
      response
    };
  }

  public async put<TResponse = void>(
    path: string,
    body: unknown,
    params?: Record<string, string>,
    init: ApiFetchBodyRequestInit = {}
  ): Promise<ApiFetchResponse<TResponse>> {
    const url = this.buildURL(path, params);
    const headers = this.mergeHeaders(init.headers);

    const response = await this.executeFetch(url, {
      ...init,
      method: 'PUT',
      headers,
      body: JSON.stringify(body)
    });

    await this.checkResponse(response);
    const data = await this.getDataFromResponse<TResponse>(response);

    return {
      data,
      response
    };
  }

  private async executeFetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response | undefined> {
    try {
      const response = await fetch(input, init);
      return response;
    } catch (error) {
      if ((error as Error).name !== 'AbortError') {
        throw error;
      }
    }
  }

  private async getDataFromResponse<TResponse>(response: Response): Promise<TResponse | undefined> {
    if (!response) {
      return;
    }

    try {
      const json = await response.json();
      return json;
    } catch (error) {
      return;
    }
  }

  private async checkResponse(response: Response | undefined): Promise<void> {
    if (!response) {
      return;
    }

    if (!response.ok) {
      const responseBody = await response.json();

      for (const interceptor of ApiFetchClient.responseInterceptors) {
        interceptor.handle(response, responseBody);
      }

      throw new ApiFetchError(response, responseBody);
    }
  }

  private buildURL(path: string, params: Record<string, string>): URL {
    const url = new URL(this.baseUrl);
    url.pathname = `${url.pathname}${path}`;

    if (params) {
      Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
    }

    return url;
  }

  private mergeHeaders(newHeaders: HeadersInit = {}): HeadersInit {
    return {
      ...ApiFetchClient.baseHeaders,
      ...newHeaders
    };
  }
}
