import { lazy } from 'react';
import { Route } from 'react-router-dom';

const Bonifications = lazy(() => import('./bonifications.route'));

export const privateRoutes = (
  <>
    <Route path="/bonifications" element={<Bonifications />} />
  </>
);
