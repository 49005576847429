import { BillingCycle, BillingCycleYearsGroup, groupBillingCyclesByYear } from '~/app/shared/billing-cycle';
import { chunkArray } from '../chunk.helper';

function transformBillingCyclesToDropdownDataSingle(
  groupedByYear: BillingCycleYearsGroup,
  selectedCycle: BillingCycle
) {
  return Object.keys(groupedByYear).map((year) => {
    const quarters = chunkArray(groupedByYear[year], 3);
    const yearNode = {
      label: year,
      children: [],
      //value: [],
      className: 'no-select-tree',
      disabled: true
    };
    //const flatSelectedCycles = selectedCycles.map((y) => y.id);

    quarters.forEach((quarter, index) => {
      const quarterNode = {
        label: `Q${index + 1} - ${year}`,
        children: [],
        //value: [],
        className: 'no-select-tree',
        disabled: true
      };

      quarter.forEach((monthCycle) => {
        const monthNode = {
          label: `${monthCycle.month} - ${year}`,
          value: monthCycle.id,
          checked: selectedCycle.id === monthCycle.id,
          disabled: false
        };

        quarterNode.children.push(monthNode);
        //quarterNode.value.push(monthCycle.id);
      });

      yearNode.children.push({
        ...quarterNode,
        //checked: quarterNode.value.every((x) => flatSelectedCycles.includes(x))
        expanded: quarterNode.children.some((m) => m.value === selectedCycle.id)
      });

      //yearNode.value = [...yearNode.value, ...quarterNode.value];
    });

    return {
      ...yearNode,
      //checked: yearNode.value.every((x) => flatSelectedCycles.includes(x)),
      expanded: yearNode.children.some((q) => q.children.some((m) => m.value === selectedCycle.id))
    };
  });
}

export function billingCyclesToDropdownTreeSingle(cycles: BillingCycle[], selectedCycle: BillingCycle) {
  const groupedByYear = groupBillingCyclesByYear(cycles);
  const transformedData = transformBillingCyclesToDropdownDataSingle(groupedByYear, selectedCycle);

  return transformedData;
}
