import { Team } from '../team/team.model';

export type Unit = {
  id: number;
  name: string;
  businessLine?: {
    name: string;
    id: number;
  };
  teams?: Team[];
  color: string;
  displayName: string;
  order: number;
};

export const RESEARCH_UNIT_NAME = 'Research';
export const RESEARCH_UNIT_SHORT_NAME = 'RS';
