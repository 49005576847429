import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './styles/app.css';
import App from './app/App';
import { authConfig, reactPlugin } from './app/shared';

import { beforeMount } from './hook';

const publicClientApplication = new PublicClientApplication(authConfig);

beforeMount().then(() => {
  ReactDOM.render(
    <MsalProvider instance={publicClientApplication}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </MsalAuthenticationTemplate>
      </AppInsightsContext.Provider>
    </MsalProvider>,
    document.getElementById('root')
  );
});
