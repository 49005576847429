import './shared-loader-ui-spinner.css';

export interface SharedLoaderUiSpinnerProps {
  color: 'orange' | 'purple' | 'blue';
  size?: 'xs' | 'md';
}

export const SharedLoaderUiSpinner: React.FunctionComponent<SharedLoaderUiSpinnerProps> = ({ color, size }) => {
  const computedSize = size ?? 'md';

  return (
    <div className="shared-loader__spinner--container">
      <div
        className={`shared-loader__spinner shared-loader__spinner--${computedSize} shared-loader__spinner--${color}`}
      ></div>
    </div>
  );
};
