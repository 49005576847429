import {
  Employee,
  EmployeeByRoleResponse,
  EmployeeDetail,
  HttpFetchResponse,
  Paginated,
  PaginatedEmployee,
  PaginationParameters,
  ProductDetailEmployeesEmployee
} from '~/app/shared';
import { ProjectEmployeesEmployee, ProjectEmployeesEmployeeDayByDay } from '~/app/shared/projects';
import { ListAPIService, ListOptions } from '~/hooks/useListFetch';
import { ApiFetchClient, ApiFetchResponse } from '~/services/ApiFetchClient';
import { PostExternalEmployee, PutExternalEmployee, PutInternalEmployee } from '../types/EmployeeTypes';

export interface EmployeesServiceGetByProductIdAndBillingCycleIdOptions {
  productId: number;
  billingCycleId: number;
  abortController: AbortController;
}

export interface GetByProjectIdAndBillingCycleIdBody {
  projectId: number;
  billingCycleId: number;
  abortController: AbortController;
}

export interface EmployeesServiceGetOptions {
  filter: string;
  paginationParameters: PaginationParameters;
  abortController: AbortController;
}

export interface EmployeesServiceGetCurrent {
  abortController: AbortController;
}

export type EmployeeCreateModel = {
  name: string;
  email: string;
  employeeRoleId: number;
};

class EmployeesService implements ListAPIService<PaginatedEmployee<EmployeeDetail>> {
  private readonly apiFetchClient: ApiFetchClient;

  constructor() {
    this.apiFetchClient = new ApiFetchClient('employee');
  }

  public async create(body: PostExternalEmployee): Promise<ApiFetchResponse<void>> {
    return this.apiFetchClient.post('', body);
  }

  public async updateInternal(body: PutInternalEmployee): Promise<ApiFetchResponse<void>> {
    return this.apiFetchClient.put('', body);
  }

  public async updateExternal(body: PutExternalEmployee): Promise<ApiFetchResponse<void>> {
    return this.apiFetchClient.put('/external', body);
  }

  public async getFiltered(
    body: ListOptions,
    abortController: AbortController
  ): Promise<ApiFetchResponse<PaginatedEmployee<EmployeeDetail>>> {
    return this.apiFetchClient.post<PaginatedEmployee<EmployeeDetail>>('/list', body, undefined, {
      signal: abortController.signal
    });
  }

  public async delete(id: number): Promise<ApiFetchResponse<void>> {
    return this.apiFetchClient.delete(`/${id}`);
  }

  public async unarchive(id: number): Promise<ApiFetchResponse<void>> {
    return this.apiFetchClient.post(`/${id}/unarchive`, undefined);
  }

  public async getServiceUsersInMainCompany(
    abortController: AbortController
  ): Promise<ApiFetchResponse<EmployeeByRoleResponse[]>> {
    return this.apiFetchClient.get('/service-users-in-main-company', undefined, {
      signal: abortController.signal
    });
  }

  // TODO: Below methods have to be migrated to use the new ApiFetchClient

  public async getEngineers(skip = 0, take = 10, filter = ''): Promise<HttpFetchResponse<Paginated<Employee>>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/employee/engineers/${skip}/${take}`;

    if (filter) url.searchParams.set('filter', filter ?? '');

    const response = await fetch(url);

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getManagers(): Promise<HttpFetchResponse<Employee[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);

    url.pathname = '/employee/managers';

    const response = await fetch(url);

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getEmployeesExternal(): Promise<HttpFetchResponse<Employee[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);

    url.pathname = '/employee/externals';

    const response = await fetch(url);

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getEmployeesAccountControllers(): Promise<HttpFetchResponse<Employee[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);

    url.pathname = '/employee/accountControllers';

    const response = await fetch(url);

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getCurrent(options: EmployeesServiceGetCurrent): Promise<HttpFetchResponse<Employee>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = '/employee/current';

    const request: RequestInit = { signal: options.abortController.signal };

    const response = await fetch(url, request);

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getByProductIdAndBillingCycleId(
    options: EmployeesServiceGetByProductIdAndBillingCycleIdOptions
  ): Promise<HttpFetchResponse<ProductDetailEmployeesEmployee[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/employee/product/${options.productId}/billing-cycle/${options.billingCycleId}`;

    const response = await fetch(url, { signal: options.abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getByProjectIdAndBillingCycleIdDayByDay(
    options: GetByProjectIdAndBillingCycleIdBody
  ): Promise<HttpFetchResponse<ProjectEmployeesEmployeeDayByDay[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/employee/project/${options.projectId}/billing-cycle/${options.billingCycleId}/day-by-day`;

    const response = await fetch(url, { signal: options.abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getByProjectIdAndBillingCycleId(
    options: GetByProjectIdAndBillingCycleIdBody
  ): Promise<HttpFetchResponse<ProjectEmployeesEmployee[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/employee/project/${options.projectId}/billing-cycle/${options.billingCycleId}`;

    const response = await fetch(url, { signal: options.abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }
}

export const employeesService = new EmployeesService();
