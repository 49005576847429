import { Suspense, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { PageSpin } from '~/components/PageSpin';
import { authModule } from './auth';
import { billingsModule } from './billings';
import { billingsUnitModule } from './billings-unit';
import { bonificationsModule } from './bonifications';
import { effortReportsModule } from './effort-reports';
import { employeesModule } from './employees';
import { forecastModule } from './forecast';
import { globalReportsModule } from './global-reports';
import { NavBar } from './layout';
import { notFoundModule } from './not-found';
import { productsModule } from './products';
import { projectsModule } from './projects';
import { proposalsModule } from './proposals';
import { AuthAction, AuthSubject, useAuthStore, useCan } from './shared';
import { teamsModule } from './teams';

export const AppRouter: React.FunctionComponent = () => {
  const [authState] = useAuthStore();
  const { can } = useCan();

  const routesConfig = (
    <>
      {can(AuthAction.Read, AuthSubject.Forecast) ? forecastModule.routes : null}
      {can(AuthAction.Read, AuthSubject.Team) ? teamsModule.routes : null}
      {can(AuthAction.Read, AuthSubject.EmployeeList) ? employeesModule.routes : null}
      {can(AuthAction.Read, AuthSubject.ProjectList) ? projectsModule.listRoutes : null}
      {can(AuthAction.Read, AuthSubject.Project) ? projectsModule.detailRoutes : null}
      {can(AuthAction.Read, AuthSubject.ProjectFinancial) ? projectsModule.financialDetailRoutes : null}
      {can(AuthAction.Read, AuthSubject.Product) ? productsModule.publicRoutes : null}
      {can(AuthAction.Read, AuthSubject.Billing) ? billingsModule.routes : null}
      {can(AuthAction.Read, AuthSubject.BillingUnit) ? billingsUnitModule.routes : null}
      {can(AuthAction.Read, AuthSubject.EffortReport) ? effortReportsModule.routes : null}
      {can(AuthAction.Read, [
        AuthSubject.GlobalAccumulatedReport,
        AuthSubject.GlobalAvailableCapacityReport,
        AuthSubject.GlobalBillableReport
      ])
        ? globalReportsModule.routes
        : null}
      {can(AuthAction.Read, AuthSubject.ProposalList) ? proposalsModule.listRoutes : null}
      {can(AuthAction.Read, AuthSubject.Proposal) ? proposalsModule.detailRoutes : null}
      {can(AuthAction.Read, AuthSubject.BonificationsReport) ? bonificationsModule.privateRoutes : null}

      {productsModule.publicRoutes}
    </>
  );

  const defaultPath = useMemo(() => {
    const firstAllowedRoute = routesConfig.props?.children?.find((x) => x);

    const getFirstAllowedRoute = (route: JSX.Element, parentPath?: string): string => {
      const { path, children } = route.props;
      if (children && children.length > 0) {
        return getFirstAllowedRoute(children[0], path);
      }

      if (children) {
        return children.props?.path;
      }

      if (route.props.index) {
        return parentPath;
      }

      return path;
    };

    return getFirstAllowedRoute(firstAllowedRoute);
  }, [routesConfig.props.children]);

  return (
    <>
      <NavBar />

      <main className="main">
        <Suspense fallback={<PageSpin />}>
          <Routes>
            {authState.maintenance ? <></> : routesConfig}
            {notFoundModule.routes}
            {authModule.routes}

            <Route path="/auth/login" element={<Navigate to="/" />} />

            <Route path="/" element={<Navigate replace to={defaultPath} />} />
            <Route path="*" element={<Navigate replace to="/not-found" />} />
          </Routes>
        </Suspense>
      </main>
    </>
  );
};
