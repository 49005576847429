export const authConfig = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENTID,
    authority: `${process.env.REACT_APP_AUTH_INSTANCE}/${process.env.REACT_APP_AUTH_TENANTID}`,
    redirectUri: window.location.origin + '/auth/login'
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

export const authRequestConfig = {
  scopes: ['User.Read']
};
