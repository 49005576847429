export interface DynamicStyleClassOptions {
  sort: boolean;
  capacity: number;
}

export const DYNAMIC_STYLE_CLASS_DEFAULT_OPTIONS: DynamicStyleClassOptions = {
  sort: false,
  capacity: -1
};

export class DynamicStyleClass {
  private readonly _source = new Map<string, boolean>();
  private readonly _options: DynamicStyleClassOptions;

  private readonly _separator = ' ';

  constructor(options: Partial<DynamicStyleClassOptions> = {}) {
    this._options = { ...DYNAMIC_STYLE_CLASS_DEFAULT_OPTIONS, ...options };
  }

  public add(c: string, evaluator = true): DynamicStyleClass {
    if (!~this._options.capacity || this._source.size < this._options.capacity) {
      this._source.set(c, !!evaluator);
      return this;
    }

    throw new RangeError(`Max capacity of DynamicSizeClass. Expected capacity <=${this._options.capacity}`);
  }

  public remove(c: string): DynamicStyleClass {
    for (const str of c.split(this._separator)) {
      if (this._source.has(str)) this._source.delete(str);
    }

    return this;
  }

  public clear(): DynamicStyleClass {
    this._source.clear();

    return this;
  }

  public toggle(c: string): DynamicStyleClass {
    for (const str of c.split(this._separator)) {
      if (this._source.has(str)) this.add(str, !this._source.get(str));
    }

    return this;
  }

  public fromString(c: string, evaluator = true): DynamicStyleClass {
    for (const str of c.split(this._separator)) this.add(str, !!evaluator);

    return this;
  }

  public fromRecord(record: Record<string, boolean>): DynamicStyleClass {
    for (const key in record) this.add(key, !!record[key]);

    return this;
  }

  public clone(): DynamicStyleClass {
    const cloned = new DynamicStyleClass(this._options);

    this._source.forEach((value, key) => cloned.add(key, value));

    return cloned;
  }

  public toString(): string {
    return this.toArray().join(this._separator);
  }

  public toArray(): string[] {
    let result: string[] = [];

    this._source.forEach((value, key) => {
      if (value) result.push(key);
    });

    if (this._options.sort) {
      result = result.sort((a, b) => a.localeCompare(b));
    }

    return result;
  }

  public println(): DynamicStyleClass {
    console.debug(`DynamicStyleClass[${new Date().toISOString()}]: "${this}"`);

    return this;
  }

  public *[Symbol.iterator]() {
    for (const cls of this.toArray()) {
      yield cls;
    }
  }
}
