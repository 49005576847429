export const UnitBadge: React.FunctionComponent<{
  name: string;
  onClick?: () => void;
  disabled?: boolean;
  color: string;
}> = ({ name, onClick, disabled = false, color }) => {
  return (
    <span
      onClick={onClick}
      data-shadow-on="row:hover"
      className="unit-display"
      style={{
        borderTop: disabled ? `3px solid var(--color-primary-palette-grey-05)` : `3px solid ${color}`,
        backgroundColor: disabled ? 'var(--color-primary-palette-grey-03)' : 'var(--color-primary-palette-grey-01)'
      }}
    >
      {name.slice(-2)}
    </span>
  );
};
