const TEAM_KEY = 'team';
const FILTERS_KEY = 'filters';

export function getSavedTeamId(): string {
  return localStorage.getItem(TEAM_KEY);
}

export function saveTeamId(teamId: string) {
  localStorage.setItem(TEAM_KEY, teamId);
}

export function getSavedFilter(filterKey: string) {
  const data = localStorage.getItem(`${FILTERS_KEY}_${filterKey}`);

  try {
    return JSON.parse(data);
  } catch (e) {
    return;
  }
}

export function saveFilter(filterKey: string, data: unknown) {
  localStorage.setItem(`${FILTERS_KEY}_${filterKey}`, JSON.stringify(data));
}
