import { AuthAction } from './auth-action.enum';
import { AuthSubject } from './auth-subject.enum';

export function mapAuthAction(action: AuthAction | AuthAction[]): string {
  const actionTuple = Object.entries(AuthAction).find(([key, value]) => value === action);

  return actionTuple && actionTuple[0].toLocaleLowerCase();
}

export function mapAuthSubject(subject: AuthSubject | AuthSubject[]): string {
  const subjectTuple = Object.entries(AuthSubject).find(([key, value]) => value === subject);

  return subjectTuple && subjectTuple[0].toLowerCase();
}
