import { lazy } from 'react';
import { Route } from 'react-router-dom';

const Proposals = lazy(() => import('./Proposals'));
const ProposalsById = lazy(() => import('./ui/detail/proposal-detail'));

export const listRoutes = (
  <>
    <Route path="/proposals" element={<Proposals />} />
  </>
);

export const detailRoutes = (
  <>
    <Route path="/proposals/:id" element={<ProposalsById />} />
  </>
);
