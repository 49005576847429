import toast from 'react-hot-toast';

import { MaterialCheckCircleIcon, MaterialCloseIcon, MaterialErrorIcon, MaterialWarningIcon } from '../icons';

class ToastService {
  private readonly commonToastOptions = {
    style: {
      padding: 0,
      width: 'auto',
      height: 'auto'
    }
  };

  private triggerSuccessToast(message: string | JSX.Element): void {
    const options = this.commonToastOptions;

    toast(
      (t) => (
        <div className="react-hot-toast">
          <div className="react-hot-toast__icon--success">
            <MaterialCheckCircleIcon size={24} />
          </div>

          <output className="react-hot-toast__message">{message}</output>

          <div className="react-hot-toast__action--dismiss" onClick={() => toast.dismiss(t.id)}>
            <MaterialCloseIcon size={20} />
          </div>
        </div>
      ),
      options
    );
  }

  private triggerErrorToast(message: string | JSX.Element): void {
    const options = this.commonToastOptions;

    toast(
      (t) => (
        <div className="react-hot-toast">
          <div className="react-hot-toast__icon--error">
            <MaterialErrorIcon size={24} />
          </div>

          <output className="react-hot-toast__message">{message}</output>

          <div className="react-hot-toast__action--dismiss" onClick={() => toast.dismiss(t.id)}>
            <MaterialCloseIcon size={20} />
          </div>
        </div>
      ),
      options
    );
  }

  private triggerWarningToast(message: string | JSX.Element): void {
    const options = this.commonToastOptions;

    toast(
      (t) => (
        <div className="react-hot-toast">
          <div className="react-hot-toast__icon--warning">
            <MaterialWarningIcon size={24} />
          </div>

          <output className="react-hot-toast__message">{message}</output>

          <div className="react-hot-toast__action--dismiss" onClick={() => toast.dismiss(t.id)}>
            <MaterialCloseIcon size={20} />
          </div>
        </div>
      ),
      options
    );
  }

  public success(payload: string): void {
    this.triggerSuccessToast(payload);
  }

  public error(payload: any) {
    const isApiError = !!payload.response?.data.errors;
    const message = isApiError ? Object.values(payload.response?.data.errors).join('\n') : payload.message;

    this.triggerErrorToast(message);
  }

  public errorMessage(message: string | JSX.Element) {
    this.triggerErrorToast(message);
  }

  public warningMessage(message: string | JSX.Element) {
    this.triggerWarningToast(message);
  }
}

export const toastService = new ToastService();
