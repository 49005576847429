import {
  ContentTypes,
  HttpFetchResponse,
  HttpMethod,
  Paginated,
  Pagination,
  PaginationParameters,
  ProblemDetails,
  ProblemDetailsErrorsExtension,
  Product,
  ProductsSummary
} from '~/app/shared';
import { insertPaginationIntoUrl } from '~/utils/urlUtils';
import { ProductEffortSummary } from '../model';

export interface ProductServiceGetPaginatedOptions {
  filter: string;
  paginationParameters: PaginationParameters;
  abortController: AbortController;
}

export interface ProductServiceProductByIdOptions {
  id: string | number;
  abortController: AbortController;
}

export interface ProductServiceProductsByQuoteIdOptions {
  quoteId: string;
  abortController: AbortController;
}

export interface ProductServiceProductOverscope {
  id: number;
  changes: number;
  nonBillable: boolean;
  employeeId: number;
  startDate: string;
  endDate: string;
  abortController: AbortController;
}

export interface ProductServiceProductOverscopeByQuoteId {
  quoteId: string;
  abortController: AbortController;
}

export interface ProductServiceGetProductByTeamAndYear {
  year: number;
  month?: number;
  teamId: string | number;
  paginationParameters: PaginationParameters;
  abortController?: AbortController;
}

export interface ProductServiceGetRegularProductEffort {
  paginationParameters: PaginationParameters;
  abortController?: AbortController;
}

export type ProductServiceGetSelector = {
  year: number;
  month?: number;
  teamId?: number;
  search?: string;
  paginationParameters: PaginationParameters;
};

class ProductsService {
  public getSortProperty(propertySort: string): string {
    const properties = {
      name: 'Name',
      quoteId: 'QuoteId',
      clientName: 'ClientName',
      status: 'StatusValue',
      scope: 'ScopeInDays',
      scopeInDays: 'ScopeInDays',
      type: 'Type',
      lastActivity: 'LastActivity',
      managementDays: 'ManagementDays',
      projectName: 'Project.Name'
    };

    return properties[propertySort] || properties.name;
  }

  ///Use in Forecast
  public async getProductsSummary(
    options: ProductServiceGetProductByTeamAndYear
  ): Promise<HttpFetchResponse<[ProductsSummary, Pagination]>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    const currentYear = new Date().getFullYear();

    url.pathname = `/product/summary/${options.year ?? currentYear}/${options.teamId}`;
    url.searchParams.set('pageNumber', `${options.paginationParameters.pageNumber}`);
    url.searchParams.set('pageSize', `${options.paginationParameters.pageSize}`);
    url.searchParams.set('propertySort', `${options.paginationParameters.propertySort}`);
    url.searchParams.set('isAscending', `${options.paginationParameters.isAscending}`);

    const response = await fetch(url, { signal: options.abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      const responsePagination = JSON.parse(response.headers.get('x-pagination'));

      return { data: [responseBody, responsePagination], status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async getForSelector({
    year,
    ...params
  }: ProductServiceGetSelector): Promise<HttpFetchResponse<Paginated<Product>>> {
    let url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/product/selector/${year}`;

    if (params.month) {
      url.searchParams.set('month', `${params.month}`);
    }
    if (params.teamId) {
      url.searchParams.set('teamId', `${params.teamId}`);
    }
    if (params.search) {
      url.searchParams.set('search', `${params.search}`);
    }

    url = insertPaginationIntoUrl(url, params.paginationParameters);

    const response = await fetch(url);

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  ///Use in Forecast
  public async getOverscopeById(
    options: ProductServiceProductOverscope
  ): Promise<HttpFetchResponse<{ isOverScope: boolean }>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/product/${options.id}/overscope`;
    url.searchParams.set('changes', `${options.changes}`);
    url.searchParams.set('nonBillable', `${options.nonBillable}`);
    url.searchParams.set('employeeId', `${options.employeeId}`);
    url.searchParams.set('startDate', `${options.startDate}`);
    url.searchParams.set('endDate', `${options.endDate}`);

    const response = await fetch(url);

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  //TODO: use in products (deprecated) and use in projects
  public async getProductsNonBillableEffort(
    options: ProductServiceGetRegularProductEffort
  ): Promise<HttpFetchResponse<[ProductsSummary, Pagination]>> {
    const url = new URL(process.env.REACT_APP_API_URL);

    url.pathname = `/product/non-billable`;
    url.searchParams.set('pageNumber', `${options.paginationParameters.pageNumber}`);
    url.searchParams.set('pageSize', `${options.paginationParameters.pageSize}`);
    url.searchParams.set('propertySort', `${options.paginationParameters.propertySort}`);
    url.searchParams.set('isAscending', `${options.paginationParameters.isAscending}`);

    const response = await fetch(url, { signal: options.abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      const responsePagination = JSON.parse(response.headers.get('x-pagination'));

      return { data: [responseBody, responsePagination], status: response.status };
    }

    return { data: null, status: response.status };
  }

  //TODO: use in products (deprecated)
  public async create(product: Product): Promise<HttpFetchResponse<ProblemDetails & ProblemDetailsErrorsExtension>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = '/product';

    const body = {
      name: product.name,
      clientName: product.clientName,
      scope: product.scope,
      scopeValue: product.scopeValue,
      status: product.status,
      quoteId: product.quoteId,
      type: product.type,
      managementDays: product.managementDays || null,
      color: product.color
    };

    const response = await fetch(url, {
      method: HttpMethod.POST,
      body: JSON.stringify(body),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      }
    });

    if (!response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  //TODO: use in products (deprecated)
  public async getPaginated(
    options: ProductServiceGetPaginatedOptions
  ): Promise<HttpFetchResponse<Paginated<Product>>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = '/product/filtered';
    url.searchParams.set('pageNumber', `${options.paginationParameters.pageNumber}`);
    url.searchParams.set('pageSize', `${options.paginationParameters.pageSize}`);
    url.searchParams.set('propertySort', this.getSortProperty(options.paginationParameters.propertySort));
    url.searchParams.set('isAscending', `${options.paginationParameters.isAscending}`);
    url.searchParams.set('filter', options.filter);

    const response = await fetch(url, { signal: options.abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  //TODO: use in products (deprecated)
  public async getById(options: ProductServiceProductByIdOptions): Promise<HttpFetchResponse<Product>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/product/${options.id}`;

    const response = await fetch(url, { signal: options.abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  //TODO: use in products (deprecated)
  public async getByQuoteId(options: ProductServiceProductsByQuoteIdOptions): Promise<HttpFetchResponse<Product[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/product/quote/${options.quoteId}`;

    const response = await fetch(url, { signal: options.abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody.items, status: response.status };
    }

    return { data: null, status: response.status };
  }

  //TODO: use in products (deprecated)
  public async delete(
    id: string | number,
    abortController: AbortController
  ): Promise<HttpFetchResponse<ProblemDetails>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/product/${id}`;

    const response = await fetch(url, { method: HttpMethod.DELETE, signal: abortController.signal });

    if (!response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  //TODO: use in products (deprecated)
  public async update(dto: Product): Promise<HttpFetchResponse<ProblemDetails & ProblemDetailsErrorsExtension>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = '/product';

    const response = await fetch(url, {
      method: HttpMethod.PUT,
      body: JSON.stringify(dto),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      }
    });

    if (!response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }

  //TODO: use in products (deprecated)
  public async getEffortSummary(
    options: ProductServiceProductByIdOptions
  ): Promise<HttpFetchResponse<ProductEffortSummary>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/product/${options.id}/effort`;

    const response = await fetch(url, { signal: options.abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }
}

export const productsService = new ProductsService();
