import {
  ContentTypes,
  FormSelectOption,
  GlobalReport,
  GlobalReportAVGItems,
  HttpFetchResponse,
  HttpMethod
} from '~/app/shared';

class GlobalReportService {
  public async capacityReportRequest(
    teamIds: number[],
    billingCycles: number[],
    abortController: AbortController
  ): Promise<HttpFetchResponse<GlobalReport[]>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = '/globalReport/capacity-report';
    url.searchParams.set('teamIds', `${teamIds}`);
    url.searchParams.set('billingCyclesIds', `${billingCycles}`);

    const response = await fetch(url, { signal: abortController.signal });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody.items, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async billableReportRequest(
    unitIds: FormSelectOption[],
    billingCycles: FormSelectOption[],
    abortController: AbortController
  ): Promise<HttpFetchResponse<[GlobalReport[], GlobalReportAVGItems[]]>> {
    if (!unitIds) return { data: [[], []], status: 0 };

    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = '/globalReport/billable-report';

    const body = {
      unitIds: unitIds,
      billingCyclesIds: billingCycles
    };

    const response = await fetch(url, {
      method: HttpMethod.POST,
      body: JSON.stringify(body),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      },
      signal: abortController.signal
    });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: [responseBody.items, responseBody.avgItems], status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async availableCapacityReportRequest(
    unitIds: FormSelectOption[],
    billingCycles: FormSelectOption[],
    abortController: AbortController
  ): Promise<HttpFetchResponse<GlobalReport[]>> {
    if (!unitIds) return { data: [], status: 0 };

    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = '/globalReport/available-capacity-report';

    const body = {
      unitIds: unitIds,
      billingCyclesIds: billingCycles
    };

    const response = await fetch(url, {
      method: HttpMethod.POST,
      body: JSON.stringify(body),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      },
      signal: abortController.signal
    });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody.items, status: response.status };
    }

    return { data: null, status: response.status };
  }

  public async accumulatedBillableReportRequest(
    unitIds: FormSelectOption[],
    billingCycles: FormSelectOption[],
    abortController: AbortController
  ): Promise<HttpFetchResponse<Array<{ billingCycle: string }>>> {
    if (!unitIds) return { data: [], status: 0 };

    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = '/globalReport/accumulated-billable-report';

    const body = {
      unitIds: unitIds,
      billingCyclesIds: billingCycles
    };

    const response = await fetch(url, {
      method: HttpMethod.POST,
      body: JSON.stringify(body),
      headers: {
        [ContentTypes.Key]: ContentTypes.ApplicationJSON
      },
      signal: abortController.signal
    });

    if (response.ok) {
      const responseBody = await response.json();

      return { data: responseBody.items, status: response.status };
    }

    return { data: null, status: response.status };
  }

  private uniqueFilter(value, index, self) {
    return self.indexOf(value) === index;
  }

  public mapResults(
    globalReports: GlobalReport[],
    businessAreas: FormSelectOption[],
    watchedCycles: FormSelectOption[],
    avgItems: GlobalReportAVGItems[] = [],
    label = 'Average'
  ) {
    const mappedResults: Array<{ billingCycle: string }> = [];

    const billingCycle = watchedCycles
      ? watchedCycles.sort((a, b) => Number(a?.value) - Number(b?.value)).map((gr) => gr.value.toString())
      : globalReports.map((gr) => gr.billingCycle).filter(this.uniqueFilter);

    billingCycle.forEach((item: string, index: number) => {
      mappedResults.push({
        billingCycle: watchedCycles?.find((x) => x.value === Number(item))?.label + ' - ' + item.substring(0, 4)
      });

      const bcFilteredByMonth = globalReports.filter((x) => x.billingCycle === item);
      const addedItem = mappedResults[index];

      if (avgItems?.length) {
        addedItem[label] = avgItems.filter((x) => x.billingCycle === item).map((gr) => gr.value)[0];
      }

      businessAreas?.forEach((area: FormSelectOption) => {
        const value = bcFilteredByMonth
          .filter((billingCycle) => +area.value === billingCycle.filter)
          .map((gr) => gr.value)[0];

        const billableDays = bcFilteredByMonth
          .filter((gr) => +area.value === gr.filter)
          .map((gr: any) => gr.billableDays)[0];
        const totalIdleUsers = bcFilteredByMonth
          .filter((gr) => +area.value === gr.filter)
          .map((gr) => gr.totalIdleUsers)[0];
        const nextKey: string = area.label;
        const nextValue: number = value ? parseFloat(value.toFixed(1)) : 0;
        addedItem[nextKey] = nextValue;
        addedItem[nextKey.concat('__billable')] = billableDays ?? 0;
        addedItem[nextKey.concat('__noEmployees')] = totalIdleUsers ?? 0;
      });
    });

    return mappedResults;
  }
}

export const globalReportService = new GlobalReportService();
