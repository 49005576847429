import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';

export function composeAuthenticationScopes(): string[] {
  return ['User.Read'];
}

export async function composeAuthenticationTokenByMsalInstance(
  instance: IPublicClientApplication
): Promise<string | undefined> {
  const [account]: AccountInfo[] = instance.getAllAccounts();

  if (account === undefined) return;

  const { idToken } = await instance.acquireTokenSilent({ account, scopes: composeAuthenticationScopes() });

  return `Bearer ${idToken}`;
}
