import { toastService } from '~/app/shared';
import { ApiFetchError } from '../ApiFetchError';
import { IResponseInterceptor } from '../IResponseInterceptor';
import type { DomainErrorResponseBody } from '../types';

export class DomainErrorResponseInterceptor implements IResponseInterceptor {
  public handle(response: Response, responseBody: any): void {
    if (response.status !== 400) {
      return;
    }

    if (responseBody?.type && responseBody?.type === 'about:blank' && responseBody.exceptionDetails) {
      const responseParsed: DomainErrorResponseBody = responseBody;

      toastService.errorMessage(responseParsed.detail);
      throw new ApiFetchError(response, responseBody, true);
    }
  }
}
