import { Dispatch, SetStateAction } from 'react';
import { isAbortError } from '../error';
import { HttpFetchResponse } from '../http';
import { BillingCycle, BillingCycleProject, BillingCycleYearsGroup } from './billing-cycle.model';

export async function billingCyclesPromiseFilter(
  cycles: BillingCycle[],
  getTwoYearsFromCurrent: (abortController: AbortController) => Promise<HttpFetchResponse<BillingCycle[]>>,
  setCycles: Dispatch<SetStateAction<BillingCycle[]>>,
  inputValue: string,
  abortController: AbortController
) {
  const text = inputValue.toLowerCase();

  let cyclesFiltered = cycles?.filter((x) => x.month.toLowerCase().includes(text));

  if (!inputValue) {
    try {
      const { data } = await getTwoYearsFromCurrent(abortController);
      cyclesFiltered = data;
      setCycles(data);
    } catch (error) {
      if (!isAbortError(error)) {
        console.error(error);
      }
    }
  }

  return cyclesFiltered?.map((x) => ({ value: x.id, label: `${x.month} - ${x.year}` }));
}

export function groupBillingCyclesByYear(cycles: BillingCycle[]): BillingCycleYearsGroup {
  return cycles.reduce((result, item) => {
    const { year } = item;

    if (!result[year]) {
      result[year] = [];
    }

    result[year].push(item);

    return result;
  }, {});
}

export function converterToBillingCycle(project: BillingCycleProject[]): BillingCycle[] {
  const billings: BillingCycle[] = [];
  project.forEach((p) => {
    const { id, year, endDate, month, monthNumber, fullDate, totalEffort, billableEffort, nonBillableEffort } = p;
    const billing = {
      id,
      year,
      endDate,
      month,
      monthNumber,
      fullDate,
      totalEffort,
      billingCycleData: billableEffort,
      nonBillingCycleData: nonBillableEffort
    };
    billings.push(billing);
  });
  return billings;
}
