import { toastService } from '~/app/shared';
import { ApiFetchError } from '../ApiFetchError';
import { IResponseInterceptor } from '../IResponseInterceptor';
import type { FluentErrorResponseBody } from '../types';

export class FluentErrorResponseInterceptor implements IResponseInterceptor {
  public handle(response: Response, responseBody: any): void {
    if (response.status !== 400) {
      return;
    }

    if (responseBody?.errors && typeof responseBody?.errors === 'object') {
      const responseParsed: FluentErrorResponseBody = responseBody;
      const errors = responseParsed?.errors;

      this.printErrors(errors);
      throw new ApiFetchError(response, responseBody, true);
    }
  }

  private printErrors(errors: Record<string, string[]>): void {
    const keys = Object.keys(errors);
    if (keys.length < 1) {
      return;
    }

    for (const key of keys) {
      for (const errorMessage of errors[key]) {
        toastService.errorMessage(errorMessage);
      }
    }
  }
}
