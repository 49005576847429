import { enUS, es } from 'date-fns/locale';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// English
import enJSON from '../assets/locales/en.json';
import esJson from '../assets/locales/es.json';

export enum Language {
  English = 'en',
  Spanish = 'es'
}

const resources = {
  [Language.English]: {
    translation: enJSON
  },
  [Language.Spanish]: {
    translation: esJson
  }
};

const monthResources = {
  [Language.English]: enUS,
  [Language.Spanish]: es
};

export function composei18n(): void {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: Language.English, // Change current language on the end of this file
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });
}

export const t = i18n.t;

export function getCurrentLanguage(): string {
  return i18n.language ?? Language.English;
}

export type LanguageReturnType = string[];

export function getLanguagesList(): LanguageReturnType[] {
  return [
    ['English', Language.English],
    ['Español', Language.Spanish]
  ];
}

export function changeCurrentLanguage(language: Language): void {
  i18n.changeLanguage(language);
}

export function existsLanguageByCode(code: string): boolean {
  const languages: LanguageReturnType[] = getLanguagesList();
  let exists = false;

  languages.forEach((lang) => {
    if (lang[0].toLowerCase() === code.toLowerCase()) {
      exists = true;
      return;
    }
  });

  return exists;
}

export function i18nMonth(month: number): string {
  const monthTranslator = monthResources[getCurrentLanguage()];

  return monthTranslator?.localize.month(month);
}

export function i18nDay(day: number): string {
  const monthTranslator = monthResources[getCurrentLanguage()];

  return monthTranslator?.localize.day(day);
}
