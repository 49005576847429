import { lazy } from 'react';
import { Route } from 'react-router-dom';

const NotFound = lazy(() => import('./NotFound'));

export default (
  <>
    <Route path="/not-found" element={<NotFound />} />
  </>
);
