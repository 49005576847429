import DropdownTreeSelect, { TreeData } from 'react-dropdown-tree-select';
import './dropdown-tree-single.css';

type DropdownTreeSingleProps = {
  data: TreeData;
  onChange?: (value: number) => void;
  disabled?: boolean;
};

export const DropdownTreeSingle = (props: DropdownTreeSingleProps) => {
  const { data, onChange = () => null, disabled = false } = props;

  const handleSelectChange = (currentNode, selectedNodes) => {
    if (currentNode.value !== undefined && typeof currentNode.value === 'number') {
      onChange(currentNode.value);
    }
  };

  return (
    <div className="dropdown-tree-single">
      <DropdownTreeSelect
        disabled={disabled}
        data={data}
        onChange={handleSelectChange}
        //keepOpenOnSelect={true}
        //keepTreeOnSearch={true}
        //showPartiallySelected={showSelectedValue}
        mode="radioSelect"
        //keepChildrenOnSearch={true}
      />
    </div>
  );
};
