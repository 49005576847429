import { lazy } from 'react';
import { Route } from 'react-router-dom';

const Billing = lazy(() => import('./list/billings'));

export const routes = (
  <>
    <Route path="/billing" element={<Billing />} />
    <Route path="/billing/:year" element={<Billing />} />
    <Route path="/billing/:year/:month" element={<Billing />} />
  </>
);
