import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { Show } from '../show';

export function ModalAction({
  showModal,
  confirmModal,
  hideModal,
  titleModal = 'modal.action-confirmation',
  bodyModal = 'modal.information',
  additionalInfo = null,
  disabledActions = false,
  showSpinner = false
}) {
  const [t] = useTranslation();

  return (
    <Modal show={showModal} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t(titleModal)}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{additionalInfo ? <p>{additionalInfo}</p> : <p>{t(bodyModal)}</p>}</Modal.Body>

      <Show
        when={!showSpinner}
        fallback={
          <div
            style={{
              display: 'grid',
              placeContent: 'start center',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '100%',
              height: '100%'
            }}
          >
            <span
              style={{
                width: '40px',
                height: '40px',
                border: '3px solid var(--color-primary-palette-grey-01)',
                borderLeftColor: 'var(--color-primary-palette-grey-06)',
                animation: 'spin 1s linear infinite',
                borderRadius: '50%',
                display: 'block'
              }}
            />
          </div>
        }
      ></Show>

      <Modal.Footer>
        <Button variant="secondary" onClick={hideModal} disabled={disabledActions}>
          {t('modal.cancel')}
        </Button>

        <Button variant="primary" onClick={() => confirmModal()} disabled={disabledActions}>
          {t('modal.accept')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
