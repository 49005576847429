import {
  ColumnDef,
  OnChangeFn,
  PaginationState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { useCallback, useRef, useState } from 'react';
import { Show } from '~/app/shared';
import { MaterialFunnelIcon } from '../icons/material/Funnel';
import { FilterPopup } from './FilterPopup';
import { FilterState } from './FilterState';
import './TanTable.css';

interface TanTableProps {
  columns: ColumnDef<any, any>[];
  columnVisibility?: VisibilityState;
  data: unknown[];
  onPaginationChange: OnChangeFn<PaginationState>;
  onSortingChange: OnChangeFn<SortingState>;
  onFilterChange?: OnChangeFn<FilterState>;
  pageCount: number;
  pagination: PaginationState;
  sorting: SortingState;
  filters?: FilterState;
  filtersOptions?: { [columnkey: string]: { value: number; label: string }[] };
}

export const TanTable: React.FunctionComponent<TanTableProps> = ({
  columns,
  columnVisibility,
  data,
  onPaginationChange,
  onSortingChange,
  onFilterChange,
  pageCount,
  pagination,
  sorting,
  filters,
  filtersOptions
}) => {
  const table = useReactTable({
    columns: columns as ColumnDef<unknown, any>[],
    data: data,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    //getFilteredRowModel: getFilteredRowModel(),
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onPaginationChange,
    onSortingChange,
    onColumnFiltersChange: onFilterChange,
    pageCount: pageCount,
    state: { sorting, pagination, columnVisibility, columnFilters: filters },
    defaultColumn: {
      size: 100
    }
  });

  const [activeFilterColumn, setActiveFilterColumn] = useState<ColumnDef<any, any> | null>(null);
  const headerRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const getHeaderRef = useCallback((header: string) => {
    return (el: HTMLDivElement | null) => {
      headerRefs.current[header] = el;
    };
  }, []);

  // Takes a window of pages of size preventing left index overflow exception. For example:
  //
  // If the pagination has a pageCount of 16 and the current page of index 10,
  // this function will return: [8, 9, 10!, 11, 12].
  //
  // If the pagination has a pageCount of 16 and the current page of index 1,
  // this function will return: [0, 1!, 2, 3].
  function takePageWindowOf(size = 2): number[] {
    return new Array(table.getPageCount())
      .fill(0)
      .map((_, i) => i)
      .slice(
        table.getState().pagination.pageIndex - size > -1 ? table.getState().pagination.pageIndex - size : 0,
        table.getState().pagination.pageIndex + size + 1
      );
  }

  return (
    <>
      <div className="tantable-container">
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{ minWidth: `${header.getSize()}px`, maxWidth: `${header.getSize() * 2}px` }}
                  >
                    {!header.isPlaceholder && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        <div
                          onClick={header.column.getToggleSortingHandler()}
                          style={{
                            cursor: header.column.getCanSort() ? 'pointer' : 'inherit',
                            userSelect: header.column.getCanSort() ? 'none' : 'inherit',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem'
                          }}
                        >
                          {header.column.columnDef.header as React.ReactNode}

                          {header.column.getCanSort() && (
                            <svg
                              fill="none"
                              width="20"
                              height="20"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                color: header.column.getIsSorted()
                                  ? 'var(--table-core-arrow-color-active)'
                                  : 'var(--table-core-arrow-color)',
                                transition: 'transform var(--table-core-arrow-transition-duration) ease-in-out',
                                transform: `rotate(${header.column.getIsSorted() === 'asc' ? '180deg' : '0deg'})`
                              }}
                            >
                              <path
                                d="M10.0003 4.16663V15.8333M13.3337 12.5L10.0003 15.8333M6.66699 12.5L10.0003 15.8333"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                          {header.column.columnDef.enableColumnFilter === true && (
                            <div
                              ref={getHeaderRef(header.column.columnDef.header.toString())}
                              onClick={(e) => {
                                e.stopPropagation();
                                setActiveFilterColumn(
                                  activeFilterColumn?.header === header.column.columnDef.header
                                    ? null
                                    : header.column.columnDef
                                );
                              }}
                              style={{ display: 'flex', cursor: 'pointer' }}
                            >
                              {filters.some((f) => f.id === header.column.id && f.value?.length > 0) ? (
                                <MaterialFunnelIcon size={14} color={'#2F69FF'} />
                              ) : (
                                <MaterialFunnelIcon size={14} />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr role="row" key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} role="cell" data-column-id={cell.column.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        {activeFilterColumn && !!filtersOptions[activeFilterColumn.header.toString()] && (
          <FilterPopup
            optionsList={filtersOptions[activeFilterColumn.header.toString()]}
            column={activeFilterColumn}
            onClose={() => setActiveFilterColumn(null)}
            anchorEl={headerRefs.current[activeFilterColumn?.header.toString()] || null}
            onFilterChange={(columnId, filterValue) => {
              table.getColumn(columnId)?.setFilterValue(filterValue);
            }}
            currentFilters={filters}
          />
        )}
      </div>

      <Show when={table.getPageCount() > 1}>
        <footer
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: '1rem',
            alignItems: 'center',
            padding: '2rem 0'
          }}
        >
          <nav className="pagination">
            <button
              data-preflight="true"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
              className="pagination-first-page"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none">
                <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.33" clipPath="url(#a)">
                  <path stroke="currentColor" d="M7.722 4.688 4.389 8.02l3.333 3.333" />
                  <path stroke="currentColor" d="M11.722 4.688 8.389 8.02l3.333 3.333" />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M.389.021h16v16h-16z" />
                  </clipPath>
                </defs>
              </svg>
            </button>

            <button
              data-preflight="true"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              className="pagination-previous-page"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none">
                <g>
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.333"
                    d="m10.426 4.021-4 4 4 4"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M.426.021h16v16h-16z" />
                  </clipPath>
                </defs>
              </svg>
            </button>

            {table.getPageCount() === 1 ? (
              <button data-preflight="true" className="pagination-current-page">
                {table.getState().pagination.pageIndex + 1}
              </button>
            ) : null}

            {table.getPageCount() > 1
              ? takePageWindowOf().map((i) => {
                  const isCurrentPage = table.getState().pagination.pageIndex === i;

                  return (
                    <button
                      key={'pagination-page-' + i}
                      data-preflight="true"
                      onClick={() => table.setPageIndex(i)}
                      className={isCurrentPage ? 'pagination-current-page' : 'pagination-page'}
                    >
                      {i + 1}
                    </button>
                  );
                })
              : null}

            <button
              data-preflight="true"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              className="pagination-next-page"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none">
                <g clipPath="url(#a)">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.333"
                    d="m6.648 4.021 4 4-4 4"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M.648.021h16v16h-16z" />
                  </clipPath>
                </defs>
              </svg>
            </button>

            <button
              data-preflight="true"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
              className="pagination-last-page"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none">
                <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.33" clipPath="url(#a)">
                  <path stroke="currentColor" d="M5.352 4.688 8.685 8.02l-3.333 3.333" />
                  <path stroke="currentColor" d="m9.352 4.688 3.333 3.333-3.333 3.333" />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M.686.021h16v16h-16z" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </nav>

          <span style={{ fontSize: '14px' }}>Total {table.getPageCount()} pages</span>
        </footer>
      </Show>
    </>
  );
};
