import React, { useEffect } from 'react';

export const smoothViewStyle: React.CSSProperties = { opacity: 0, transition: 'opacity .5s' };

export function composeSmoothViewStyle(duration = '.5s'): React.CSSProperties {
  return { opacity: 0, transition: `opacity ${duration}` };
}

export function useSmoothView<K extends HTMLElement>(ref: React.MutableRefObject<K>, evaluator: () => boolean) {
  useEffect(
    function smoothLoadView() {
      if (evaluator()) {
        let baseCase = 0;

        while (!ref.current && baseCase < 1000) {
          baseCase++;
        }

        if (ref.current) {
          ref.current.style.opacity = '0';
        }

        setTimeout(() => {
          if (ref.current) {
            ref.current.style.opacity = '1';
          }
        }, 100);
      }
    },
    [evaluator, ref]
  );
}
