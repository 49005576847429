import { format, subBusinessDays } from 'date-fns';
import { format as tzFormat, zonedTimeToUtc } from 'date-fns-tz';
import { t } from '~/app/app.i18n';
import { FormSelectOption } from '../form';

export enum DayOfBusinessWeek {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 0
}

export function formatDateForServer(date: Date) {
  return format(date, 'yyyy-MM-dd');
}

export function getUtcDate(date?: Date): Date {
  return zonedTimeToUtc(date || new Date(), getTimeZone());
}

export function getTimeZone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function parseDateWithTimezoneToString(date: Date): string {
  return tzFormat(date, 'yyyy-MM-dd', { timeZone: getTimeZone() });
}

export function getCurrentFullYear(): number {
  return getUtcDate().getFullYear();
}

export function getDateFromServerValue(date: Date): string {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function getLastBusinessDate(date: Date): Date {
  const removeOneBusinessDay = 1;
  return subBusinessDays(date, removeOneBusinessDay);
}

export function areSameDate(firstDate: Date, secondaryDate: Date): boolean {
  if (!firstDate || !secondaryDate) {
    return false;
  }
  return firstDate.toDateString() === secondaryDate.toDateString();
}

export function getDateFormatCustom(date: Date): string {
  return new Date(date)
    .toLocaleDateString('es-ES', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    })
    .replace('/', '-')
    .replace('/', '-');
}

export function getBillingCycleByDate(date: Date): number {
  return parseInt(format(date, 'yyyyMM'));
}

export function getValidMonthBillingCycle(month) {
  const currentMonth = getUtcDate().getMonth() + 1;
  const monthValue = parseInt(month);
  if (isNaN(monthValue)) {
    return currentMonth;
  }
  const isValid = monthValue >= 1 && monthValue <= 12;
  return isValid ? monthValue : currentMonth;
}

export function getValidYearBillingCycle(year) {
  const currentYear = getUtcDate().getFullYear();
  const yearValue = parseInt(year);
  if (isNaN(yearValue)) {
    return currentYear;
  }
  const isValid = yearValue >= 2022 && yearValue <= currentYear + 2;
  return isValid ? yearValue : currentYear;
}

export function getAllYearsBillingCycle() {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = 2022; year <= currentYear + 2; year++) {
    years.push(year);
  }
  return years;
}

export function billingYearsToSelectOptions(): FormSelectOption<unknown>[] {
  return getAllYearsBillingCycle().map((y) => ({ value: y, label: y }));
}

export function getAllMonthBillingCycle() {
  const months = [
    { numberMonth: 1, nameMonth: t('common.months.january') },
    { numberMonth: 2, nameMonth: t('common.months.february') },
    { numberMonth: 3, nameMonth: t('common.months.march') },
    { numberMonth: 4, nameMonth: t('common.months.april') },
    { numberMonth: 5, nameMonth: t('common.months.may') },
    { numberMonth: 6, nameMonth: t('common.months.june') },
    { numberMonth: 7, nameMonth: t('common.months.july') },
    { numberMonth: 8, nameMonth: t('common.months.august') },
    { numberMonth: 9, nameMonth: t('common.months.september') },
    { numberMonth: 10, nameMonth: t('common.months.october') },
    { numberMonth: 11, nameMonth: t('common.months.november') },
    { numberMonth: 12, nameMonth: t('common.months.december') }
  ];
  return months;
}

export function billingMonthsToSelectOptions(): FormSelectOption<unknown>[] {
  return getAllMonthBillingCycle().map((m) => ({ value: m.numberMonth, label: m.nameMonth }));
}
