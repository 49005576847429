import { lazy } from 'react';
import { Route } from 'react-router-dom';

const AuthForbidden = lazy(() => import('./ui/AuthForbidden'));
const NotAllowed = lazy(() => import('./ui/NotAllowed'));
const AuthMaintenance = lazy(() => import('./ui/AuthMaintenance'));

export default (
  <>
    <Route path="/auth/forbidden" element={<AuthForbidden />} />
    <Route path="/auth/maintenance" element={<AuthMaintenance />} />
    <Route path="/auth/not-allowed" element={<NotAllowed />} />
  </>
);
