export class HttpQueryStringParser {
  public encodeOneDimensionArray(
    preffix: string,
    searchParams: URLSearchParams,
    array: Array<string | number | boolean>
  ): void {
    array.forEach((value, index) => searchParams.set(`${preffix}[${index}]`, `${value}`));
  }
}
export const httpQueryStringParser = new HttpQueryStringParser();
