import { useMemo } from 'react';
import { Tooltip } from '../tooltip';

import { getInitials } from './initials.helper';

import './initials.css';

type InitialsProps = {
  text: string;
  size?: number;
  placement?: string;
  roleName: string;
};

export function Initials(props: InitialsProps) {
  const { text, size = 30, placement, roleName } = props;

  const initials = useMemo(() => getInitials(text, 2), [text]);

  return (
    text && (
      <Tooltip title={`${text} (${roleName})`} placement={placement}>
        <span
          className="initials"
          style={{
            height: `${size}px`,
            width: `${size}px`,

            fontSize: `${size * 0.4}px`
          }}
        >
          {initials}
        </span>
      </Tooltip>
    )
  );
}
