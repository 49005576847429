// This is the same than AuthSubject in the API.

export enum AuthAction {
  None,
  Manage, // Represents any Action
  Read,
  Create,
  Update,
  Delete
}
