interface MaterialCancelPresentationIconProps {
  size: 40;
}

export const MaterialCancelPresentationIcon: React.FunctionComponent<MaterialCancelPresentationIconProps> = ({
  size
}) => {
  switch (size) {
    case 40:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40">
          <path
            fill="currentColor"
            d="M15.167 26.792 20 21.958l4.833 4.834 1.959-1.959L21.958 20l4.834-4.833-1.959-1.959L20 18.042l-4.833-4.834-1.959 1.959L18.042 20l-4.834 4.833Zm-9.042 6.541q-1.125 0-1.958-.833-.834-.833-.834-1.958V9.458q0-1.125.834-1.958.833-.833 1.958-.833h27.75q1.125 0 1.958.833.834.833.834 1.958v21.084q0 1.125-.834 1.958-.833.833-1.958.833Zm0-2.791h27.75V9.458H6.125v21.084Zm0 0V9.458v21.084Z"
          />
        </svg>
      );
    default:
      break;
  }

  return null;
};
