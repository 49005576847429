import { useCallback, useRef } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select, { components as Components, GroupBase, InputProps, SelectComponentsConfig } from 'react-select';
import { FormSelectOption } from './form-select-option.model';
import './form-select.css';

type FormSelectProps<T = any> = {
  name: string;
  small?: boolean;
  options: FormSelectOption<T>[];
  control: Control<any, any>;
  placeholder?: string;
  rules?: unknown;
  customStyles?: unknown;
  className?: string;
  components?: SelectComponentsConfig<unknown, boolean, GroupBase<unknown>>;
  isDisabled?: boolean;
  isSearchable?: boolean;
  defaultValue?: FormSelectOption | FormSelectOption[];
  value?: FormSelectOption | FormSelectOption[];
  isMultiple?: boolean;
  isClearable?: boolean;
  onChange?: (value) => void;
  otherProperties?: any;
  isInvalid?: boolean;
  noOptionsMessageCustom?: string;
};

export function FormSelect<T = any>(props: FormSelectProps<T>) {
  const {
    name,
    options,
    control,
    customStyles,
    className,
    rules,
    placeholder = '',
    components,
    small,
    isDisabled,
    isSearchable = true,
    defaultValue,
    isMultiple = false,
    isClearable = false,
    onChange,
    otherProperties,
    isInvalid,
    noOptionsMessageCustom
  } = props;
  const [t] = useTranslation();
  const selectRef = useRef();

  const onFocusSelect = useCallback(() => {
    selectRef && selectRef?.current && (selectRef?.current as any)?.inputRef?.current?.focus();
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => {
        const Input = (props: InputProps) => {
          if (!props.isMulti)
            return (
              <div className={`custom-form-select__input form-select ${fieldState.invalid ? 'is-invalid' : ''}`}>
                <Components.Input {...props} />
              </div>
            );

          return <Components.Input {...props} />;
        };

        return (
          <Select<FormSelectOption<T>>
            {...field}
            {...otherProperties}
            ref={selectRef}
            styles={customStyles}
            options={options}
            components={{ Input, ...components }}
            classNamePrefix={!isMultiple ? 'custom-form-select' : 'custom-form-select-multiple'}
            blurInputOnSelect
            closeMenuOnSelect
            placeholder={placeholder}
            menuPlacement="auto"
            isClearable={isClearable}
            onFocus={onFocusSelect}
            isDisabled={isDisabled}
            loadingMessage={() => t('common.loading')}
            noOptionsMessage={() => (noOptionsMessageCustom ? noOptionsMessageCustom : t('form.type'))}
            className={`custom-form-select ${small ? 'custom-form-select--small' : ''} ${
              (!isMultiple && fieldState.invalid) || isInvalid ? 'is-invalid' : ''
            } ${className}`}
            isSearchable={isSearchable}
            defaultValue={defaultValue}
            isMulti={isMultiple}
            onChange={(value) => {
              field.onChange(value);
              !!onChange && onChange(value);
            }}
          />
        );
      }}
    />
  );
}
