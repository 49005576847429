import { useAbility } from '@casl/react';
import { createContext } from 'react';
import { AuthAction } from './auth-action.enum';
import { AuthSubject } from './auth-subject.enum';
import { mapAuthAction, mapAuthSubject } from './auth.helper';

export const AbilityContext = createContext(null);

export function useCan() {
  const ability = useAbility(AbilityContext);

  function checkAbility(action: AuthAction, subject: AuthSubject) {
    const actionName = mapAuthAction(action);
    const subjectName = mapAuthSubject(subject);

    return ability.can(actionName, subjectName);
  }

  const can = (action: AuthAction, subject: AuthSubject | AuthSubject[]) => {
    const subjects = Array.isArray(subject) ? subject : [subject];

    return subjects.some((x) => checkAbility(action, x));
  };

  const cannot = (action: AuthAction, subject: AuthSubject | AuthSubject[]) => {
    const subjects = Array.isArray(subject) ? subject : [subject];

    return subjects.some((x) => !checkAbility(action, x));
  };

  return { can, cannot };
}
