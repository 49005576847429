export interface TransformIntegerToStringFormatOptions {
  digits: number;
  preffix: string;
}

export function transformIntegerToStringFormatSync(
  input: number,
  options?: Partial<TransformIntegerToStringFormatOptions>
): string {
  if (!Number.isInteger(input)) throw TypeError('only integer is supported');

  const engine = new Intl.NumberFormat('en', { style: 'decimal', minimumIntegerDigits: options?.digits ?? 2 });
  const result = engine.format(input);

  return options?.preffix ? options.preffix.concat(result) : result;
}

export async function transformIntegerToStringFormat(
  input: number,
  options?: Partial<TransformIntegerToStringFormatOptions>
): Promise<string> {
  return new Promise<string>((resolve, reject): void => {
    try {
      const result: string = transformIntegerToStringFormatSync(input, options);

      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
}

export function displayNumberWithPointSeparator(input: number): string {
  return new Intl.NumberFormat('de-DE').format(input);
}

export function displayNumberByCurrency(input: number, currency: string): string {
  const currencyDefault = currency && currency !== '' ? currency : 'EUR';
  return new Intl.NumberFormat('de', { style: 'currency', currency: currencyDefault }).format(input);
}
