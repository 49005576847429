export function isAbortError(exception: unknown | DOMException): exception is DOMException {
  if (exception instanceof DOMException) {
    if (exception.name === 'AbortError') {
      return true;
    }
  }

  return false;
}

export function printAbortError(exception: DOMException): void {
  console.warn(exception.message);
}
