import { HttpFetchResponse, httpQueryStringParser } from '~/app/shared';

export interface BonificationsServiceGetReportOptions {
  year: string;
  employeesIds: number[];
  abortController: AbortController;
}

class BonificationsService {
  public async getReport(options: BonificationsServiceGetReportOptions): Promise<HttpFetchResponse<Blob>> {
    const url = new URL(process.env.REACT_APP_API_URL);
    url.pathname = `/bonification`;
    url.searchParams.set('year', options.year);

    httpQueryStringParser.encodeOneDimensionArray('employeeIds', url.searchParams, options.employeesIds);

    const response = await fetch(url, { signal: options.abortController.signal });

    if (response.ok) {
      const responseBody = await response.blob();

      return { data: responseBody, status: response.status };
    }

    return { data: null, status: response.status };
  }
}

export const bonificationsService = new BonificationsService();
