import { BoundCanProps } from '@casl/react';
import { useCan } from './auth-can.hook';

type CanProps = {
  children?: React.ReactNode;
} & BoundCanProps<any>;

export function Can(props: CanProps) {
  const { I, a, children } = props as any;
  const { can } = useCan();

  if (can(I, a)) {
    return children;
  }

  return <></>;
}
