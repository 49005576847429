import { lazy } from 'react';
import { Route } from 'react-router-dom';

const Projects = lazy(() => import('./list/projects'));
const ProjectDetail = lazy(() => import('./detail/project-detail'));
const ProjectFinancialDetail = lazy(() => import('./detail/project-financial-detail'));

export const listRoutes = (
  <>
    <Route path="/projects" element={<Projects />} />
  </>
);

export const detailRoutes = (
  <>
    <Route path="/projects/:id" element={<ProjectDetail />} />
  </>
);

export const financialDetailRoutes = (
  <>
    <Route path="/projects/:id/financial" element={<ProjectFinancialDetail />} />
  </>
);
