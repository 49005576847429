import { ColumnDef } from '@tanstack/react-table';
import React, { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Show } from '~/app/shared';
import useOutsideClick from '../hook/useOutsideClick';
import './FilterPopup.css';
import { FilterState } from './FilterState';

interface FilterPopupProps {
  column: ColumnDef<any, any>;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  onFilterChange: (columnId: string, filterValue: any) => void;
  currentFilters: FilterState;
  optionsList: { value: number; label: string }[];
}

export const FilterPopup: React.FC<FilterPopupProps> = ({
  column,
  onClose,
  anchorEl,
  onFilterChange,
  currentFilters,
  optionsList
}) => {
  const [t] = useTranslation();
  const [popupStyle, setPopupStyle] = useState({ top: 0, left: 0 });

  const [selectedFilters, setSelectedFilters] = useState<Set<any>>(() => {
    const currentFilter = currentFilters.find(
      (f) => f.id === (column as { accessorKey?: string }).accessorKey || column.id
    );
    return new Set(currentFilter ? currentFilter.value : []);
  });
  const popupRef = useRef<HTMLDivElement>(null);

  useOutsideClick(popupRef, onClose, { current: anchorEl });
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const updatePosition = () => {
      if (anchorEl) {
        const rect = anchorEl.getBoundingClientRect();
        setPopupStyle({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX
        });
        setIsReady(true);
      }
    };

    updatePosition();
    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', updatePosition);

    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition);
    };
  }, [anchorEl]);

  const handleCheckboxChange = (value: any) => {
    setSelectedFilters((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(value)) {
        newSet.delete(value);
      } else {
        newSet.add(value);
      }
      return newSet;
    });
  };

  const handleApplyFilter = () => {
    const columnId = (column as { accessorKey?: string }).accessorKey || column.id;
    onFilterChange(columnId, Array.from(selectedFilters));
    onClose();
  };

  if (!anchorEl) return null;

  return ReactDOM.createPortal(
    <Show when={isReady}>
      <div ref={popupRef} className="filter-popup" style={popupStyle}>
        <div className="filter-options">
          {optionsList.map((option) => (
            <Form.Check
              key={option.value}
              label={option.label}
              checked={selectedFilters.has(option.value)}
              onChange={() => handleCheckboxChange(option.value)}
            />
          ))}
        </div>

        <Stack direction="horizontal" gap={3} style={{ justifyContent: 'flex-end' }}>
          <Button size="sm" variant="outline-primary" onClick={onClose}>
            {t('common.close')}
          </Button>
          <Button size="sm" onClick={handleApplyFilter}>
            {t('common.apply')}
          </Button>
        </Stack>
      </div>
    </Show>,
    document.body
  );
};
