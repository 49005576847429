import { PaginationParameters } from '~/app/shared';

export function insertPaginationIntoUrl(url: URL, params: PaginationParameters): URL {
  if (params.propertySort) {
    url.searchParams.set('propertySort', `${params.propertySort}`);
  }
  if (params.isAscending) {
    url.searchParams.set('isAscending', `${params.isAscending}`);
  }

  url.searchParams.set('pageNumber', `${params.pageNumber}`);
  url.searchParams.set('pageSize', `${params.pageSize}`);

  return url;
}
