import { Tooltip } from '..';

interface TooltipBaseProps {
  text: string;
  content?: string;
  linked?: boolean;
  disabled?: boolean;
  disableStyle?: boolean;
  flag?: string;
  colorStyle?: string;
}

export const TooltipBase: React.FunctionComponent<TooltipBaseProps> = ({
  text,
  content,
  linked,
  disableStyle,
  flag,
  disabled,
  children,
  colorStyle
}) => {
  const color = disableStyle ? null : linked ? 'var(--bs-primary)' : colorStyle ? colorStyle : 'var(--bs-gray-800)';

  return (
    <Tooltip title={text} disabled={disabled}>
      <span data-flag={flag || 'none'} style={{ color }}>
        {children || (content ?? text)}
      </span>
    </Tooltip>
  );
};
